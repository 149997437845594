import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { t } from "i18next"
import { searchClient, priceLineID, segmentationInsert } from '../config.js'
import { GetGlobalBannerData, ShowLoader, HideLoader, scrollTop, onBannerView } from '../functions'
import { getMeilisearchIndexName } from '../helpers/apiHelper'

import { Product } from "../components/ProductCard"
import LoadableImage from "../components/LoadableImage/LoadableImage"

import '../assets/css/mainpage.css'

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'

import { InstantSearch, Configure, Hits } from 'react-instantsearch-dom'

import { ReactComponent as AllProducts } from '../assets/svg/mpallproducts.svg'
import { ReactComponent as OnSale } from '../assets/svg/mponsale.svg'
import { ReactComponent as Favorite } from '../assets/svg/mpfavorite.svg'

const bookletFacetFilters = [
    `itemStocks.source_warehouse_id=${localStorage.getItem('warehouse.id')}`,
    localStorage.getItem('marketingCampaignIds')
        ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.booklet}]`
        : `is_booklet=true`,
    `itemSegmentations IN [${segmentationInsert}]`,
]
const alcoholicFacetFilters = [
    `itemStocks.source_warehouse_id=${localStorage.getItem('warehouse.id')}`,
    localStorage.getItem('marketingCampaignIds')
        ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.promo}]`
        : `is_discount=true`,
    `itemSegmentations IN [${segmentationInsert}]`,
]

const productHit = ({ hit }) => {
    return (
        <Product key={`product${hit.id}`} {...hit} pricelineId={priceLineID} isGrid={true} />
    )
}

export const MainPage = () => {
    const navigate = useNavigate()

    const [topBannerLoaded, setTopBannerLoaded] = useState(true)
    const [bannersTop, setBannersTop] = useState([])
    const [bannersMiddle, setBannersMiddle] = useState([])
    const [bannersBottom, setBannersBottom] = useState([])

    useEffect(() => {
        scrollTop()
        ShowLoader()
        const data = JSON.parse(localStorage.getItem('banners_data'))
        data && setTopBannerLoaded(true)
        data && data.forEach(e => {
            e.content_subtype_id === 'yPrdNR7m4nbgEjmq' && e.contentType?.data?.real_id === 2 && setBannersBottom(GetGlobalBannerData(e))
            e.content_subtype_id === 'RgpxYPa3MnJjqMw8' && e.contentType?.data?.real_id === 2 && setBannersMiddle(GetGlobalBannerData(e))
            e.content_subtype_id === 'k9peXdnvO7DQ0m5y' && e.contentType?.data?.real_id === 2 && setBannersTop(GetGlobalBannerData(e))
        })
        setTopBannerLoaded(false)
        HideLoader()
    }, [])
    
    return (
        <div className="content main_page">
            <div style={{height: topBannerLoaded || bannersTop.length > 0 ? '300px' : '20px'}}>
                {(topBannerLoaded || bannersTop.length > 0) &&
                    <div className="main_page_banner">
                        <Carousel showThumbs={false} showStatus={false} swipeable={true}>
                            {bannersTop.map((e, i) => <div key={`bannerslide${i}`} onClick={() => onBannerView(e, navigate)} className="main_page_banner__single">
                                <LoadableImage src={e.image} alt={e.title}/>
                            </div>)}
                        </Carousel>
                    </div>
                }
            </div>
            <MainPageMenu />
            <LatestBooklet />
            <>
                <h2 className="center">{t('niki.b2b.mainpage.discover')}</h2> 
                {bannersMiddle.length > 0 && bannersMiddle.map((e, i) => <div onClick={() => onBannerView(e, navigate)} key={`bottombanner${i}`} className="discover_more">
                    <LoadableImage src={e.image} alt={e.title} height={'152px'}/>
                </div>)}
                {bannersBottom.length > 0 && bannersBottom.map((e, i) => <div onClick={() => onBannerView(e, navigate)} key={`bottombanner${i}`} className="discover_more">
                    <LoadableImage src={e.image} alt={e.title} height={'152px'}/>
                </div>)}
            </>
            <AlcoholicBeverages />
        </div>
    )
}

const MainPageMenu = () => {
    return (
        <>
            <h2 className="center">{t('niki.b2b.mainpage.startnavigating')}</h2>
            <ul className="main_page__menu">
                <li>
                    <NavLink to="/all-products/">
                        <span className="main_page__menu__button"><AllProducts /></span>
                        <span className="main_page__menu__text">{t('niki.b2b.returnproducts.products')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/all-products/?show=discount">
                        <span className="main_page__menu__button"><OnSale /></span>
                        <span className="main_page__menu__text">{t('niki.b2b.mainpage.onsale')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/all-products/?show=favorites">
                        <span className="main_page__menu__button"><Favorite /></span>
                        <span className="main_page__menu__text">{t('niki.b2b.menu.favorites')}</span>
                    </NavLink>
                </li>
            </ul>
        </>
    )
}

const LatestBooklet = () => {
    return (
        <InstantSearch
            indexName={getMeilisearchIndexName("items")}
            searchClient={searchClient}
        >
            <Configure
                facetFilters={bookletFacetFilters}
                hitsPerPage={12}
                attributesToHighlight={[]}
                attributesToRetrieve={['id','ItemExtrafieldsAnswers','main_unit_code_translations','sale_unit_code_translations','company_id','entity_account_id','tax_group_id','source_id','status_id','attachment_id','source_item_id','source_family_id','source_supplier_id','source_item_type_id','source_barcode_type_id','main_unit_code','sale_unit_code','purchase_unit_code','barcode','matchcode','capacity','capacity_unit_code','width','height','length','netweight','grossweight','properties','order_apply_round','order_round_percent','order_round_unit_code','family','itemStocks',`itemPrices.${priceLineID}`,`itemDiscounts.${priceLineID}`,'is_booklet','is_new','itemUnitBarcodeByUnitCodeBox','ratings','attachment','attachments','is_discount','default_warehouse','is_in_stock','itemDescriptions','brand','country','kinds']}
            />
            <h2 className="center">{t('niki.b2b.mainpage.featuredbooklet')}</h2>
            <div className="product_hits"><Hits hitComponent={productHit} /></div>
        </InstantSearch>
    )
}

const AlcoholicBeverages = () => {
    return (
        <InstantSearch
            indexName={getMeilisearchIndexName("items")}
            searchClient={searchClient}
        >
            <Configure
                facetFilters={alcoholicFacetFilters}
                hitsPerPage={12}
                attributesToHighlight={[]}
                attributesToRetrieve={['id','ItemExtrafieldsAnswers','main_unit_code_translations','sale_unit_code_translations','company_id','entity_account_id','tax_group_id','source_id','status_id','attachment_id','source_item_id','source_family_id','source_supplier_id','source_item_type_id','source_barcode_type_id','main_unit_code','sale_unit_code','purchase_unit_code','barcode','matchcode','capacity','capacity_unit_code','width','height','length','netweight','grossweight','properties','order_apply_round','order_round_percent','order_round_unit_code','family','itemStocks',`itemPrices.${priceLineID}`,`itemDiscounts.${priceLineID}`,'is_booklet','is_new','itemUnitBarcodeByUnitCodeBox','ratings','attachment','attachments','is_discount','default_warehouse','is_in_stock','itemDescriptions','brand','country','kinds']}
            />
            <h2 className="center">{t('niki.b2b.mainpage.alcoholic')}</h2>
            <div className="product_hits"><Hits hitComponent={productHit} /></div>
        </InstantSearch>
    )
}