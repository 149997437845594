import React, { useState, useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from 'react-redux'
import axios from "axios"
import { t } from "i18next"
import PhoneInput from 'react-phone-input-2'

import { xCompanyID, companyCountryShort, priceLineID, feedbackPolicyID, xTenant } from '../config.js'
import { ShowLoader, HideLoader, generateLocatilization } from "../functions.js"
import { getRequestOptions } from '../helpers/apiHelper'
import { setErrorMessage } from '../reduxToolkit/allproduct'

import { BreadCrumbs } from "../components/BreadCrumbs"
import { TwoContacts } from "../components/TwoContacts"
import { AlertPopup } from "../components/AlertPopup.jsx"

import './../assets/css/feedback.css'

import { ReactComponent as Printer } from './../assets/svg/feedbackprinter.svg'
import { ReactComponent as UploadImages } from './../assets/svg/feedbackuploadimages.svg'

const DateFormat = localDate => localDate && localDate.length > 0 && localDate.split(' ')[0].replaceAll('/','.') 
const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)

const CreateFeedback = async (state) => {
    const searchParams = new URLSearchParams(state)
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/feedbacks?${searchParams.toString()}`,getRequestOptions('POST'))
    const json = await response.json()
    return json
}

const GetOrdersData = async () => {
    // const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/documents?include=details,status&searchFields=account_id%3A%3D%3Bkey%3A%3D&realKey=true&limit=10000&search=account_id%3A${localStorage.getItem('account.entity.id.real')}%3Bkey%3ANE&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`,requestOptions)
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/documents?include=details,status,documentType&showVat=true&realKey=true&page=1&limit=15&search=documentType.id:1;account_id:${localStorage.getItem('account.entity.id.real')};key:FAC2,FAC&searchFields=key:in;account_id:in;documentType.id:in&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`,getRequestOptions())
    const json = await response.json()
    return json
}

const GetFeedbackTypes = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/feedback-types`,getRequestOptions())
    const json = await response.json()
    return json
}
const GetFeedbackSubTypes = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/feedback-types?include=feedbackSubtypes`,getRequestOptions())
    const json = await response.json()
    return json
}

const defaultDataState = {
    entity_account_id: localStorage.getItem('account.entity.id'),
    process_id: 'yPrdNR7m4nbgEjmq',
    document_id: '',
    type_id: '',
    subtype_id: '',
    status_id: 'J8RvMwowm7k2pNOr',
    matchcode: '',
    reply_name: '',
    telephone: '',
    reply_email: '',
    description: '',
}

export const SubmitFeedback = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const IHaveReadEl = useRef(null)
    const EmailErrorAlertEl = useRef(null)
    const PhoneErrorAlertEl = useRef(null)
    const SubjectErrorAlertEl = useRef(null)
    const FormGridEl = useRef(null)
    const subtypeSelectlEl = useRef(null)
    const subtypeLabelEl = useRef(null)

    const [step,setStep] = useState('one')
    const [messageID,setMessageID] = useState('')
    const [feedbackID,setFeedbackID] = useState('')
    const [uploadedImages,setUploadedImages] = useState([])
    const [formSubmited,setFormSubmited] = useState(false)
    const [emailValid,setEmailValid] = useState(false)
    const [phoneValid,setPhoneValid] = useState(false)
    const [subjectValid,setSubjectValid] = useState(false)
    const [feedbackOrders,setFeedbackOrders] = useState([])
    const [feedbackTypes,setFeedbackTypes] = useState([])
    const [feedbackSubTypes,setFeedbackSubTypes] = useState([])
    const [subtypesCount,setSubtypesCount] = useState({})
    const [documentID,setDocumentID] = useState('')
    const [typeID,setTypeID] = useState('')
    const [state,setState] = useState(defaultDataState)
    const [loadingStatus,setLoadingStatus] = useState('waiting')
    const [disabled, setDisabled] = useState(false)
    const [isFeedbackPolicyAlert, setIsFeedbackPolicyAlert] = useState(false)
    const [isAcceptTermsAlert, setIsAcceptTermsAlert] = useState(false)
    const [feedbackPolicyText, setFeedbackPolicyText] = useState('')

    const ShowFeedbackPolicy = (e) => {
        e.preventDefault()
        setIsFeedbackPolicyAlert(true)
    }

    const FormValuesChange = e => {
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value
        setState({...state,[e.target.name]:value})
        e.target.name === 'reply_email' && setEmailValid(isEmail(value))
        e.target.name === 'matchcode' && setSubjectValid(e.target.value.length > 0 ? true : false)
        if (e.target.name === 'type_id') CheckSubtypes(value)
    }
    const onChangeValuePhone = value => {
        setPhoneValid(value.length > 0)
        setState({ ...state, telephone: `+${value}`})
    }
    const CheckSubtypes = value => {
        if (subtypesCount[value] > 0) {
            subtypeLabelEl.current.classList.remove('hidden')
            subtypeSelectlEl.current.classList.remove('hidden')
        } else {
            subtypeLabelEl.current.classList.add('hidden')
            subtypeSelectlEl.current.classList.add('hidden')
        }
    }

    const GoToSecondStep = () => {
        setFormSubmited(true)
        if (IHaveReadEl.current.checked && emailValid) {
            ShowLoader()
            CreateFeedback(state)
            .then(json => {
                if (json.data) {
                    const message_id = json.data.message_id
                    setFeedbackID(json.data.id)
                    setMessageID(json.data.message_id)
                    setState({...state,message_id:message_id})
                    FormGridEl.current.scrollIntoView()
                    setStep('two')
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        } else {
            !IHaveReadEl.current.checked && setIsAcceptTermsAlert(true)
            !emailValid && EmailErrorAlertEl.current.scrollIntoView()
            !subjectValid && SubjectErrorAlertEl.current.scrollIntoView()
        }
    }

    const SelectFile = e => {
        let data = new FormData()

        const files = Array.from(e.target.files)
        files.forEach(file => data.append('attachments[]',file))

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'X-Tenant': xTenant,
                'X-CompanyID': xCompanyID,
                'Authorization': `Bearer ${localStorage.getItem('access.token')}`,
            },
        }
        ShowLoader()
        axios.post(`${process.env.REACT_APP_API_DOMAIN}/messages/${messageID}/comment-upload`,data,config)
        .then(res => {
            if (res.data && res.data.length > 0) {
                res.data.forEach(e => {
                    const url = e.url
                    setUploadedImages(prevState => ([...prevState, url]))
                })
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    const ConfirmSubmit = () => {
        setDisabled(true)
        setLoadingStatus('loading')
        const requestOptionsComment = getRequestOptions('POST', null, 'application/json')
        requestOptionsComment.body = JSON.stringify({
                text: state.description,
                type: 'feedback'
            })
        
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/messages/${messageID}/comments`,requestOptionsComment)
            .then(response => response.json())
            .then(json => {
                json?.message && dispatch(setErrorMessage(json?.message))
                if (json.data) navigate(`/feedback-recieved/${feedbackID}/`)
            })
            .catch(error => console.error(error))
            .finally(() => {
                setDisabled(false)
                setLoadingStatus('waiting')
                HideLoader()
            })
    }

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_DOMAIN}/privacy-policies?id=${feedbackPolicyID}`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            json.data && setFeedbackPolicyText(json.data.filter(e => e.real_id === feedbackPolicyID)[0].body)
        })

        ShowLoader()
        GetOrdersData()
            .then(json => {
                const compareDates = (a, b) => {
                    const dateA = new Date(a.date.split('.').reverse().join('-'));
                    const dateB = new Date(b.date.split('.').reverse().join('-'));
                    return dateA - dateB;
                }
                setFeedbackOrders([])
                const feedbackOrdersData = []

                json.data && json.data.forEach(e => {
                    const tempObj = {
                        id: e.id,
                        idReal: e.id_real,
                        date: DateFormat(e.created_at),
                        key: `${e.key} - ${e.serial}`
                    }
                    feedbackOrdersData.push(tempObj)
                })
                feedbackOrdersData.sort(compareDates).reverse()
                setFeedbackOrders(feedbackOrdersData)
                setDocumentID(json.data[0].id)
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())

        ShowLoader()
        GetFeedbackTypes()
        .then(json => {
            setFeedbackTypes([])
            json.data && json.data.forEach(e => {
                const tempObj = {
                    id: e.id,
                    name: e.matchcode,
                }
                setFeedbackTypes(prevState => ([...prevState, tempObj]))
            })
            setTypeID(json.data[0].id)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())

        try {
            ShowLoader()
            GetFeedbackSubTypes()
            .then(json => {
                let localSubtypesCount = {}
                json.data && json.data.forEach((e,i) => {
                    setFeedbackSubTypes([])
                    const localType = e.id
                    localSubtypesCount[localType] = (e.feedbackSubtypes && e.feedbackSubtypes.data && e.feedbackSubtypes.data.length) || 0
                    if (i === 0) CheckSubtypes(localType)
                    if (e.feedbackSubtypes && e.feedbackSubtypes.data && e.feedbackSubtypes.data.length > 0) {
                        e.feedbackSubtypes.data.forEach(j => {
                            const tempObj = {
                                id: j.id,
                                name: j.matchcode,
                                type: localType,
                            }
                            setFeedbackSubTypes(prevState => ([...prevState, tempObj]))
                        })
                    }
                })
                setSubtypesCount(localSubtypesCount)
            })
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        setState({
            ...state,
            type_id: typeID,
            document_id: documentID,
        })
    // eslint-disable-next-line
    },[documentID,typeID])

    return (
        <div className="content">
            <BreadCrumbs itemlist={[
                {'url':'/account/','title':t('niki.b2b.menu.myaccount')},
                {'url':'/feedback/','title':t('niki.b2b.menu.feedback')},
                {'url':'/submit-feedback/','title':t('niki.b2b.feedback.order')},
            ]} />
            <div className="feedback_content">
                {step === 'one' &&
                    <>
                        <h1>{t('niki.b2b.feedback.step1')}</h1>
                        <div className="under_h1">{t('niki.b2b.feedback.step1sub')}</div>
                    </>
                }
                {step === 'two' &&
                    <>
                        <h1>{t('niki.b2b.feedback.step2')}</h1>
                        <div className="under_h1">{t('niki.b2b.feedback.step2sub')}</div>
                    </>
                }
                
                <div className="feedback_submit__form" ref={FormGridEl}>
                    {step === 'one' &&
                        <>
                            <label className="feedback__label" htmlFor="type_id">{t('niki.b2b.feedback.ftype')} <span className="red">*</span></label>
                            <select
                                className="form-control"
                                name="type_id"
                                id="type_id"
                                onChange={FormValuesChange}
                            >
                                {feedbackTypes.map((e,i)=><option key={`feddbacktype${i}`} value={e.id}>{e.name}</option>)}
                            </select>
                            
                            <label ref={subtypeLabelEl} className="feedback__label" htmlFor="subtype_id">{t('niki.b2b.feedback.ftype')} <span className="red">*</span></label>
                            <select
                                className="form-control"
                                name="subtype_id"
                                id="subtype_id"
                                onChange={FormValuesChange}
                                ref={subtypeSelectlEl}
                            >
                                {feedbackSubTypes.map((e,i)=><option key={`feddbacksubtype${i}`} value={e.id}>{e.name}</option>)}
                            </select>
                            
                            {formSubmited && !subjectValid && <div ref={SubjectErrorAlertEl} className="alert alert-danger">{t('niki.b2b.feedback.subjectalert')}</div>}
                            <label className="feedback__label" htmlFor="feedbacksubject">{t('niki.b2b.feedback.subject')} <span className="red">*</span></label>
                            <input className="form-control" id="feedbacksubject" name="matchcode" placeholder={t('niki.b2b.feedback.complaintsubject')} onChange={FormValuesChange} value={state.matchcode} required />
                            
                            <label className="feedback__label" htmlFor="document_id">{t('niki.b2b.feedback.referringorder')}</label>
                            <select
                                className="form-control"
                                id="document_id"
                                name="document_id"
                                onChange={FormValuesChange}
                            >
                                {feedbackOrders.length > 0 && feedbackOrders.map((e,i) => <option key={`feedbackordersli${i}`} value={e.id}>{e.key} {e.idReal} - {e.date}</option>)}
                            </select>

                            <label className="feedback__label" htmlFor="сustomerтumber">{t('niki.b2b.feedback.customernumber')} <span className="red">*</span></label>
                            <input className="form-control" id="сustomerтumber" value={`${localStorage.getItem('account.entity.id.real')}`} disabled />

                            <label className="feedback__label" htmlFor="yourname">{t('niki.b2b.feedback.yourname')} <span className="red">*</span></label>
                            <input className="form-control" id="yourname" placeholder={t('niki.b2b.feedback.yourname')} name="reply_name" onChange={FormValuesChange} value={state.reply_name} required />
                            
                            {formSubmited && !phoneValid && <div ref={PhoneErrorAlertEl} className="alert alert-danger">{t('niki.b2b.feedback.phonealert')}</div>}

                            <label className="feedback__label" htmlFor="telephonenumber">{t('niki.b2b.feedback.telephone')} <span className="red">*</span></label>
                            <div className="wrapprePhone">
                                <PhoneInput
                                    localization={generateLocatilization()}
                                    country={companyCountryShort || 'gb'}
                                    placeholder={t('niki.b2b.login.phonenumber')}
                                    preferredCountries={['it','de','fr','gr','ru','es','pt','gb']}
                                    onChange={onChangeValuePhone}
                                />
                            </div>
                            
                            {formSubmited && !emailValid && <div ref={EmailErrorAlertEl} className="alert alert-danger">{t('niki.b2b.feedback.emailalert')}</div>}

                            <label className="feedback__label" htmlFor="replyemailaddress">{t('niki.b2b.feedback.replyemail')} <span className="red">*</span></label>
                            <input className="form-control" id="replyemailaddress" placeholder={t('niki.b2b.feedback.replyemail')} name="reply_email" onChange={FormValuesChange} value={state.reply_email} required />
                        </>
                    }

                    {step === 'two' &&
                        <>
                            <label className="feedback__label" htmlFor="description">{t('niki.b2b.feedback.description')} <span className="red">*</span></label>
                            <textarea className="form-control" id="description" placeholder={t('niki.b2b.feedback.description')} name="description" onChange={FormValuesChange} value={state.description} required></textarea>

                            <div className="feedback__label">{t('niki.b2b.feedback.attachments')}</div>
                            <div className="marginbottom15"><label className="btn"><input type="file" name="file" className="form-control" onChange={SelectFile} multiple accept="image/*" />{t('niki.b2b.feedback.uploadimages')}<UploadImages /></label></div>
                            {uploadedImages && uploadedImages.length > 0 &&
                                <div className="feedback__uploaded_images">{uploadedImages.map((e,i) =>
                                    <div key={`uploadedimage${i}`} className="feedback__uploaded_image">
                                        <img src={e} alt="Uploaded" />
                                    </div>
                                )}</div>
                            }
                        </>
                    }
                    
                    {step === 'one' &&
                        <>
                            <label className="feedback__checkboxes"><input type="checkbox" ref={IHaveReadEl} /> {t('niki.b2b.feedback.acknowledge')} <span className="feedback_policy" onClick={ShowFeedbackPolicy}>{t('niki.b2b.feedback.policy')}</span></label>
                            <button type="button" className="btn btn-info" onClick={GoToSecondStep}>{t('niki.b2b.feedback.registernext')}</button>
                        </>
                    }
                    {step === 'two' &&
                        <>
                            <label className="feedback__checkboxes"><input type="checkbox" /> {t('niki.b2b.feedback.copytoemail')}</label>
                            <button
                                type="button"
                                className={[`btn btn-info submit_loader submit_loader__feedback`,loadingStatus].join(' ')}
                                onClick={ConfirmSubmit}
                                disabled={disabled}
                            >
                                <span className="waiting">{t('niki.b2b.feedback.complete')}</span>
                                <span className="loading round_spinner"></span>
                            </button>
                        </>
                    }
                </div>

                <hr />

                <div className="feedback_grey light_grey">
                    <div className="feedback_grey__header">{t('niki.b2b.feedback.help.title')}</div>
                    <div>{t('niki.b2b.feedback.help.text1')}<br />
                    {t('niki.b2b.feedback.help.text2')}</div>
                    <button className="feedback_grey__view_policy" onClick={ShowFeedbackPolicy}><Printer />{t('niki.b2b.feedback.alert.policy')}</button>
                </div>

                <div className="your_designated_support_team">{t('niki.b2b.account.supportteam')}</div>
                <TwoContacts />
            </div>
            {isFeedbackPolicyAlert && <AlertPopup isPadding={true} text={feedbackPolicyText} closeAlert={() => setIsFeedbackPolicyAlert(false)} /> }
            {isAcceptTermsAlert && <AlertPopup text={t('niki.b2b.feedback.pleaseaccept')} closeAlert={() => setIsAcceptTermsAlert(false)}/> }
        </div>
    )
}