import React  from 'react'

import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from 'react-router-dom'
import { addCartProduct, setIsLoaded, clearCartContent, setCartHeaders } from "../reduxToolkit/cartSlice"
import { setIsReadyFavorites } from "../reduxToolkit/allproduct"
import { getRequestOptions, getAllCartsData, includeForProduct, getMeilisearchIndexName } from '../helpers/apiHelper'

import { GoToLogin, RemoveHistoryLocalStorage, CheckTokenLifeTime, getCartProducts, ShowLoader, HideLoader } from './../functions'
import { priceLineID, meilisearchUrl } from './../config.js'

const guruRequestOptions = {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${process.env.REACT_APP_MEILISEARCH_SECRET_KEY}`
    }
}

const now = new Date()
const todayIs = `${String(now.getDate()).padStart(2, '0')}.${String(now.getMonth() + 1).padStart(2, '0')}.${now.getFullYear()}`

let showBanner = true
if (localStorage.getItem('top.red.banner') === 'closed') showBanner = false
if (localStorage.getItem('top.red.banner.closed') && localStorage.getItem('top.red.banner.closed') === todayIs) {
    showBanner = false
} else {
    showBanner = true
    localStorage.removeItem('top.red.banner.closed')
    localStorage.setItem('top.red.banner','open')
}

!localStorage.getItem('locale') && localStorage.setItem('locale','en')

const CreateNewCart = async () => {
    const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
    requestOptionsCart.body = JSON.stringify({
        company_id: localStorage.getItem('account.company.hashed'),
        customer_account_id: localStorage.getItem('account.entity.id'),
        status_id: 'J8RvMwowm7k2pNOr',
        realKey: '1',
    })
   
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-headers`,requestOptionsCart)
    if (parseInt(response.status) === 401) GoToLogin()
    const json = await response.json()
    return json
}

export const Header = () => {
    
    const dispatch = useDispatch()

    !localStorage.getItem('top.red.banner') && localStorage.setItem('top.red.banner','open')

    const CloseRedBanner = () => {
        localStorage.setItem('top.red.banner','closed')
        localStorage.setItem('top.red.banner.closed',todayIs)
        document.querySelector('.top_red_banner').style.display = 'none'
    }

    const location = useLocation()
    useEffect(()=>{
        localStorage.setItem('current.url', location.pathname)
        let isLogin = false

        if (localStorage.getItem('access.token.lifetime') && localStorage.getItem('access.token') && localStorage.getItem('account.user.name') && localStorage.getItem('account.entity.id') && localStorage.getItem('account.entity.name') && localStorage.getItem('enter.shop')) {
            const TOKEN_LIFETIME = parseInt(localStorage.getItem('access.token.lifetime'))
            isLogin = TOKEN_LIFETIME - parseInt(Date.now()) > 0

            !isLogin && !localStorage.getItem('isCheckTokenLifeTime') && CheckTokenLifeTime()
        }

    },[location.pathname])

    const [redBanner, setRedBanner] = useState('')
    
    useEffect(()=>{
        if (localStorage.getItem('red.banner') && localStorage.getItem('red.banner.date') && localStorage.getItem('red.banner.date') === todayIs) {
            setRedBanner(localStorage.getItem('red.banner'))
        } else {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/announcements?announcement_channel_id=RgpxYPa3MnJjqMw8`,getRequestOptions())
            .then(response => response.json())
            .then(json => {
                if (json.data && json.data[0] && json.data[0].matchcode) {
                    const redBannerTextTranslate = json.data[0]?.descriptions ? json.data[0].descriptions.filter(el => el.key === "description") : []
                    const redBannerMatchcode = json.data[0].matchcode
                    const redBannerText = redBannerTextTranslate.length > 0
                        ? redBannerTextTranslate[0].text.replaceAll('p>', '').replace('</', '').replace('<', '')
                        : redBannerMatchcode
                    setRedBanner(redBannerText)
                    localStorage.setItem('red.banner',redBannerText)
                    localStorage.setItem('red.banner.date', todayIs)
                    }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }, [])

    useEffect(()=>{
        if (!localStorage.getItem('favorites')) {
            fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("favorites")}/search?filter=user_id=${localStorage.getItem('user.id')}&limit=8000&attributesToRetrieve=favoriteable_id`,guruRequestOptions)
            .then(response => response.json())
            .then(json => {
                json.hits?.length > 0
                    ? localStorage.setItem('favorites', json.hits.map(i => i.favoriteable_id).join(','))
                    : localStorage.setItem('favorites', '')
                json.hits?.length > 0 && dispatch(setIsReadyFavorites(true))
            })
            .catch(error => console.error(error))
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!localStorage.getItem('history.ids') || localStorage.getItem('history.ids') === "undefined") {
            const attributesToRetrieve = 'attributesToRetrieve=item_id,transaction_uuid,unit_price,quantity,number,created_at'
            fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("doc_details")}/search?filter=account_id=${localStorage.getItem('account.entity.id')}&limit=1000&${attributesToRetrieve}`,guruRequestOptions)
            .then(response => response.json())
            .then(json => {
                let historyIDs = []
                RemoveHistoryLocalStorage()
                json.hits && json.hits.forEach(el => {
                    const productID = el.item_id
                    historyIDs.push(productID)
                    const tempObj = {
                        id: productID,
                        transaction_uuid: el.transaction_uuid,
                        unit_price: el.unit_price,
                        quantity: el.quantity,
                        document_serial_number: el.number,
                        created_at: el.created_at,
                    }
                    localStorage.setItem(`history_${productID}_${el.uuid}`, JSON.stringify(tempObj))
                })
                localStorage.setItem('history.ids',historyIDs.join(','))
            })
            .catch(error => console.error(error))
        }
    },[])

    useEffect(() => {
        const getCart = (cartUuid) => {
            try {
                fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-header/${cartUuid}/${localStorage.getItem('account.entity.id')}?realKey=1`, getRequestOptions())
                    .then(response => {
                        if (parseInt(response.status) === 401) GoToLogin()
                        return response.json()
                    })
                    .then(json => {
                        json.data && dispatch(setCartHeaders({
                            id: json.data.id,
                            idreal: json.data.id_real,
                            name: json.data.matchcode || ''
                        }))
                    })
            } catch (e) {
                console.error(e)
            } finally {
                HideLoader()
            }

            fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details?cart_header_uuid=${cartUuid}&include=${includeForProduct}&realKey=true&showVat=true&limit=1000&source_price_id[]=${priceLineID}&rand=${Math.random()}`, getRequestOptions())
                .then(response => response.json())
                .then(json => {
                    dispatch(clearCartContent())
                    json.data && json.data.forEach(e => {
                        const cartData = getCartProducts(e)
                        dispatch(addCartProduct(cartData))
                    })
                    dispatch(setIsLoaded('notloaded'))
                })
        }
          
        if (localStorage.getItem('cart.uuid')) {
            getCart(localStorage.getItem('cart.uuid'))
        } else {
            try {
                ShowLoader()
                const URL = `${process.env.REACT_APP_API_DOMAIN}/cart-headers/${localStorage.getItem('account.entity.id')}?include=status,company,cartDetails&realKey=1&sortBy=updated_at`

                getAllCartsData(URL)
                    .then(json => {                      
                        let updateDate = 0
                        let res = {}
                        if (json.data && json.data.length > 0) {
                            json.data.forEach(e => {
                            const date = new Date(e.updated_at).getTime()
                                if (updateDate < date) {
                                    updateDate = date
                                    res = e
                                }
                            })
                            getCart(res.uuid)
                            localStorage.setItem('cart.uuid', res.uuid)
                            localStorage.setItem('cart.id', res.id)
                            localStorage.setItem('cart.id.real', res.real_id)
                        } else {
                            CreateNewCart()
                            .then(json => {
                                if (json.data) {
                                    localStorage.setItem('cart.uuid',json.data.uuid)
                                    localStorage.setItem('cart.id',json.data.id)
                                    localStorage.setItem('cart.id.real',json.data.real_id)
                                }
                            })
                        } 
                    })
            } catch(e) {
                console.log(e.name + ': ' + e.message)
            } finally {
                HideLoader()
            }
        }
        // eslint-disable-next-line
    },[])

    return (
        <>
            {(showBanner && redBanner.length > 0 ) && 
            <div className="top_red_banner">
                {redBanner}
                <button className="top_red_banner__close" onClick={CloseRedBanner}></button>
            </div>}
            <div className="loader"></div>
        </>
    )
}