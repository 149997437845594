import { useState, memo, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

import { addCartProduct, deleteCartProduct, setProductIdOpenCart } from '../reduxToolkit/cartSlice'
import { priceLineID } from '../config'
import { CapacityString, PriceFormat, GetMainImage, LangFix, GetHistoryData, ShowLoader, HideLoader, returnItemExtrafields, getCartProducts, setCartIdsToStorage, returnProductDataForRedux } from '../functions'
import { getRequestOptions, deleteProductFormCart, includeForProduct, returnErrorDuringAddProductToCart } from '../helpers/apiHelper'

import { OrderHistoryPopup } from './OrderHistoryPopup'
import { FavoritesButton } from './FavoritesButton'
import { IconProduct } from './IconProduct'
import { IconSuplierDateDelivery } from './IconSuplierDateDelivery'
import { ProductQtyChangeButtons } from '../components/MyShoppingCart/ProductQtyChangeButtons'
import defaultImage from './../assets/images/placeholder.jpg'

import './../assets/css/allproducts.css'

import { ReactComponent as Brand } from './../assets/svg/ppbrand.svg'
import { ReactComponent as Box } from './../assets/svg/ppbox.svg'
import { ReactComponent as Cart } from './../assets/svg/ppcart.svg'
import { ReactComponent as History } from './../assets/svg/pphistory.svg'
import { ReactComponent as New } from './../assets/svg/ppnew.svg'
import { ReactComponent as Booklet } from './../assets/svg/ppbooklet.svg'

export const Product =  memo((props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const productsInCart = useSelector(state => state.cartContent.productIDs)
    const productQTYs = useSelector(state => state.cartContent.productQTYs)
    const cartDetailsIDs = useSelector(state => state.cartContent.cartDetailsIDs)
    const productIdOpenCart = useSelector(state => state.cartContent.productIdOpenCart)

    const selectedLanguage = LangFix(localStorage.getItem('locale'))
    const isViewList = localStorage.getItem('product.view') === 'list'

    const ProductID = props.id
    const ProductItemID = props.item_id || props.source_item_id
    const ProductName = (props.itemDescriptions && props.itemDescriptions[selectedLanguage] && props.itemDescriptions[selectedLanguage].short_description) || props.matchcode

    let priceActualOld = 0
    let priceActual = props.itemPrices && props.itemPrices.data && props.itemPrices.data[0] && props.itemPrices.data[0].unit_price ? parseFloat(props.itemPrices.data[0].unit_price) : 0

    if (props.itemPrices && props.itemPrices[priceLineID] && props.itemPrices[priceLineID].unit_price) priceActual = parseFloat(props.itemPrices[priceLineID].unit_price)
    
    let priceTaxActual = props.itemPrices && props.itemPrices.data && props.itemPrices.data[0] && props.itemPrices.data[0].tax_included_price ? parseFloat(props.itemPrices.data[0].tax_included_price) : 0
    if (props.itemPrices && props.itemPrices[priceLineID] && props.itemPrices[priceLineID].tax_included_price) priceTaxActual = parseFloat(props.itemPrices[priceLineID].tax_included_price)
    
    let ProductDiscount = 0
    if (props.itemDiscounts && props.itemDiscounts[priceLineID]) {
        priceActualOld = priceActual
        priceActual = parseFloat(props.itemDiscounts[priceLineID].unit_price)
        // priceTaxActualOld = priceTaxActual
        priceTaxActual = parseFloat(props.itemDiscounts[priceLineID].tax_included_price)
        ProductDiscount = Math.round((priceActualOld - priceActual)/priceActualOld*100)
    }

    const ProductMatchcode = props.matchcode
    const ProductBrand = props.itemBrand?.data?.name || (props.brand && props.brand.name && props.brand.name[selectedLanguage]) || props.brand?.name || null
    const ProductImage = props.attachment || GetMainImage(props.attachments,props.attachment_id)
    
    const ProductPrice = priceActual
    const ProductTax = priceTaxActual

    const ProductCountry = props.country?.data?.iso_3166_2 || props.country?.iso_3166_2
    const ProductCapacity = CapacityString(props.capacity,props.capacity_unit_code,ProductPrice)

    const ProductIsNew = props.is_new
    const ProductIsBooklet = props.is_booklet
    
    const ProductQtyInBox = parseInt(props.itemUnitBarcodeByUnitCodeBox?.data?.coefficient) || parseInt(props.itemUnitBarcodeByUnitCodeBox?.coefficient) || ''
    let ProductAvailable = 0
    if (props.itemStocks && props.itemStocks.data && props.itemStocks.data[0] && props.itemStocks.data[0].available) ProductAvailable = parseInt(props.itemStocks.data[0].available)
    if (props.itemStocks && props.itemStocks && props.itemStocks[0] && props.itemStocks[0].available) ProductAvailable = parseInt(props.itemStocks[0].available)
    let showStock = true
    const ProductUni = (props.main_unit_code_translations[selectedLanguage]) || props.main_unit_code_translations['en']|| props.main_unit_code
    const notShowText = (parseInt(localStorage.getItem('show.stock')) === 0 || parseInt(localStorage.getItem('show.stock')) === 1)
    
    let stockStatus = 'outofstock'
    let stockText = `${t('niki.b2b.product.outofstock')}`
    if (parseInt(localStorage.getItem('show.stock')) === 0) {
        showStock = false
    } else if (ProductAvailable > 20) {
        stockStatus = 'instock'
        const qty = `${ProductAvailable}`.length > 4 ? (`${ProductAvailable}`.slice(0,2)+"K+") : ProductAvailable
        stockText = notShowText ?  t('niki.b2b.allproducts.instock') : `${qty } ${isViewList ? ProductUni : ''}`
    } else if (ProductAvailable > 0) {
        stockStatus = 'low'
        stockText = notShowText ? t('niki.b2b.product.stocklow') : `${isViewList ? t('niki.b2b.product.stocklow') : ''} ${ProductAvailable}`
    }

    let ProductIcons = []
    if (props.ItemExtrafieldsAnswers) {
        ProductIcons.push(returnItemExtrafields(props.ItemExtrafieldsAnswers) ? returnItemExtrafields(props.ItemExtrafieldsAnswers) : { label: '', icon: '', id: '' })
    }

    const ProductHistory = (props.itemHistories && props.itemHistories.data) || GetHistoryData(props.id)
    
    const [isCheckQtyCart, setIsCheckQtyCart] = useState(false)
    const [isCloseCheckQtyCart, setIsCloseCheckQtyCart] = useState(false)
    const [isSetNewPoductId, setIsSetNewPoductId] = useState(false)

    const ShowCartOverlay = (prodID) => document.querySelector(`.cart_overlay.product${prodID}`).classList.add('active') 
    const HideCartOverlay = () => {
        dispatch(setProductIdOpenCart(''))
        if (!productQTYs[ProductID]) {
            dispatch(deleteCartProduct(cartDetailsIDs[ProductID]))
            deleteProductFormCart(cartDetailsIDs[ProductID])
        }
        setIsCloseCheckQtyCart(true)
        document.querySelector(`.cart_overlay.product${ProductID}`)?.classList?.remove('active') 
    }

    useEffect(() => {
        let timer;
        if (isCheckQtyCart) {
            clearTimeout(timer);
            timer = setTimeout(() => setIsSetNewPoductId(true), 100)
            if (productsInCart.includes(productIdOpenCart) && !productQTYs[productIdOpenCart]) {
                dispatch(deleteCartProduct(cartDetailsIDs[productIdOpenCart]))
                deleteProductFormCart(cartDetailsIDs[productIdOpenCart])
            }
        } else if (isCheckQtyCart) {
            clearTimeout(timer);
            timer = setTimeout(() => setIsSetNewPoductId(true), 100)
        }              
        return () => clearTimeout(timer)
        // eslint-disable-next-line 
    }, [isCheckQtyCart, isCloseCheckQtyCart])

    useEffect(() => {
        if (isSetNewPoductId && isCloseCheckQtyCart) {
            dispatch(setProductIdOpenCart(''))
            setIsSetNewPoductId(false)
            setIsCloseCheckQtyCart(false)
        } else if (isSetNewPoductId) {
            dispatch(setProductIdOpenCart(ProductID))
            setIsSetNewPoductId(false)
            setIsCheckQtyCart(false)
        }
        // eslint-disable-next-line 
    }, [isSetNewPoductId])
    
    useEffect(() => {
        let timer;
        if (productIdOpenCart === ProductID) {
            clearTimeout(timer);
            timer = setTimeout(() => ShowCartOverlay(productIdOpenCart), 200)
        }     
        return () => clearTimeout(timer)
        // eslint-disable-next-line 
    }, [productIdOpenCart])

    const changeQtyCart = () => {
        setIsCheckQtyCart(true)
        document.querySelector(`.cart_overlay.product${productIdOpenCart}`)?.classList?.remove('active') 
    }

    const PutToCart = e => {
        changeQtyCart()
        if (localStorage.getItem('cart.uuid')) {
            const uuid = localStorage.getItem('cart.uuid')
            ShowLoader()
            const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
            requestOptionsCart.body = JSON.stringify({
                cart_header_uuid: uuid,
                item_id: ProductID,
                matchcode: ProductMatchcode,
                variation_id: '3',
                qty_in_box: ProductQtyInBox.toString(),
                qty: ProductQtyInBox.toString(),
                unit_price: ProductPrice.toString(),
                tax_included_price: ProductTax
            })
            
            fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details?include=${includeForProduct}}&realKey=true&showVat=true&source_price_id[]=${priceLineID}`,requestOptionsCart)
            .then(response => response.json())
            .then(json => {
                if (json?.message) {
                    returnErrorDuringAddProductToCart(json.message, dispatch)
                } else if (json.data) {
                    const cartData = getCartProducts(json.data)
                    dispatch(addCartProduct(cartData))
                }
            })
            .catch(error => returnErrorDuringAddProductToCart(error, dispatch))
            .finally(() => HideLoader())
        } else {
            let detaildata = []
            detaildata[0] = {
                matchcode: props.matchcode,
                variation_id: '1',
                qty: ProductQtyInBox.toString(),
                unit_price: ProductPrice.toString(),
                tax_included_price: ProductTax.toString(),
                qty_in_box: ProductQtyInBox.toString(),
                item_id: ProductID,
            }
            const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
            requestOptionsCart.body = JSON.stringify({
                company_id: props.company_id,
                customer_account_id: localStorage.getItem('account.entity.id'),
                status_id: 'J8RvMwowm7k2pNOr',
                details: detaildata,
                realKey: '1',
            })
            
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-headers`,requestOptionsCart)
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    const {uuid, id_real, id} = json.data
                    setCartIdsToStorage(uuid, id_real, id)
                    dispatch(addCartProduct(returnProductDataForRedux(id, ProductName, ProductQtyInBox, ProductQtyInBox, ProductUni, ProductPrice, props.id, props.item_id, +ProductItemID)))
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }

    const isSuplier = props.source_item_type_id === 98 || props.source_item_type_id === 99
    const [productImgIsValid, setProductImgIsValid] = useState(ProductImage?.endsWith("g"));
    const [isOpenHistoryProduct, setIsOpenHistoryProduct] = useState(false)
    
    const ShowProdHistory = () => {
        setIsOpenHistoryProduct(true)
        window.location.pathname.includes('all-products') && document.querySelector(`.aside_filters__content__button__show_hide`).classList.add('display_none')
    }

    const onProductClick = () => {
        localStorage.setItem('url.back', `${window.location.pathname}`)
        localStorage.setItem('is_on_product_card', 'true')

        if (props.onProductClick) {
            props.onProductClick(props.id)
        } else {
            navigate(`/productpage/${props.id}/`)
        }
    }

    return (
        <div className={[`products_grid__product`,productsInCart.includes(ProductID) ? `incart` : `notincart`].join(' ')}>
            <div className="image_info_area">
                {productImgIsValid
                    ? <div className="image_area" onClick={onProductClick}>
                        <img loading="lazy" src={ProductImage} onError={() => setProductImgIsValid(false)} alt={ProductMatchcode} />
                    </div>
                    : <div className="image_area" onClick={onProductClick}>
                        <img loading="lazy" src={defaultImage} alt={ProductMatchcode} />
                    </div>
                }  
                <div className="info_area">
                    <div className="product_link" onClick={onProductClick}>
                        {ProductName}
                    </div>
                    {ProductBrand
                        ? <div className="brand_area"><Brand />{ProductBrand}</div>
                        : <div className="brand_area"></div>
                    }
                    <div className="uni_stock_area">
                        <div className="uni_area"><Box /><p>{ProductQtyInBox} {(isViewList && !props.isGrid) ? ProductUni : ''}</p></div>
                        {showStock && <div className={[`stock_area`,stockStatus].join(' ')}>{(isViewList && !props.isGrid) ? stockText : ''}</div>}
                    </div>
                </div>
            </div>
            <div className="prices_buttons_area">
                {ProductPrice
                    ? <div className="prices_area">
                        <span className={[`prices_area__price`,`is_discount_${ProductDiscount > 0}`].join(' ')}>{PriceFormat(ProductPrice)}</span>
                        <span className="prices_area__little">{ProductCapacity}</span>
                    </div>
                    : <div className="prices_area"><span className='prices_area__price' style={{color: 'white'}}>.</span></div>
                }
                <div className="buttons_area">
                    <FavoritesButton productId={props.id} isFavorites={props.is_favorite} setClickFavorites={props.setClickFavorites}/>
                    {(ProductHistory && ProductHistory.length > 0) &&
                        <>
                            <button className="buttons_area__button" onClick={ShowProdHistory}><History /></button>
                            {isOpenHistoryProduct && <OrderHistoryPopup 
                                matchcode={ProductName}
                                imgsrc={ProductImage}
                                qty_in_box={ProductQtyInBox}
                                sale_unit_code={ProductUni}
                                item_id={props.source_item_id}
                                product_id={props.id}
                                setIsOpenHistoryProduct={setIsOpenHistoryProduct}
                            />}
                        </>
                    }
                    {productsInCart.includes(ProductID)
                        ? <button className="buttons_area__button products_is_in_cart" onClick={changeQtyCart}>{productQTYs[ProductID]}</button>
                        : <button className="buttons_area__button cart" onClick={PutToCart}><Cart /></button>
                    }
                    
                </div>
            </div>
            <div className="item_id_area">{ProductItemID}</div>
            {isSuplier && <IconSuplierDateDelivery viewGrid={!isViewList} />}
            {ProductIcons.length > 0 && <div className="product_icons">{ProductIcons.map((e)=> <IconProduct icon={e} key={e.id} index={e.id} />)}</div>}
            {ProductCountry && <div className={[`country_badge`,`flag-icon-${ProductCountry.toLowerCase()}`].join(' ')}></div>}
            {ProductIsNew && <New className="new_badge" />}
            {ProductIsBooklet && <Booklet className="booklet_badge" />}
            {ProductDiscount > 0 && <div className={["discount_badge", isSuplier ? "discount_badge_if_suplier" : ''].join(' ')}>-{ProductDiscount}%</div>}
            {productIdOpenCart === ProductID && <div className={[`cart_overlay`, `product${ProductID}`].join(' ')}>
                <div className="item_id_area">{ProductItemID}</div>
                {ProductBrand && <div className="cart_overlay__brand_area">{ProductBrand}</div>}
                <div className="cart_overlay__to_cart_text">{t('niki.b2b.cartflow.addtocart')}:</div>
                <div className="cart_overlay__product_name">{ProductName}</div>
                
                <div className="cart_overlay__buttons_input">
                    {productsInCart.includes(ProductID)
                        ? <ProductQtyChangeButtons productid={ProductID} qty={productQTYs[ProductID]} qtyinbox={ProductQtyInBox} sale_unit_code={props.sale_unit_code} onDelete={() => HideCartOverlay() } />
                        : <div className="wrapper_overlay_spiner"><div className="spinner_ring_red"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div> 
                <button className="cart_overlay__close" onClick={HideCartOverlay}></button>
            </div>}
        </div>
    )
})

export const ProductPlaceholder = props => {
    const arrayTemp = [...Array(parseInt(props.elements))]
    return (
        <>{arrayTemp.map((e,i)=>
            <div className="products_grid__product__placeholder" key={`productplaceholder${i}`}>
                <div className="content_placeholder product_placeholder__itemid"></div>
                <div className="content_placeholder product_placeholder__image"></div>
                <div className="content_placeholder product_placeholder__title"></div>
                <ul className="product_placeholder__buttons">
                    <li className="content_placeholder product_placeholder__button"></li>
                    <li className="content_placeholder product_placeholder__button"></li>
                    <li className="content_placeholder product_placeholder__button"></li>
                </ul>
            </div>
        )}</>
    )
}