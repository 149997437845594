import React, { useRef, useEffect, useState }  from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { InstantSearch, Configure, Hits } from 'react-instantsearch-dom'
import { t } from "i18next"

import { addCartProduct } from '../reduxToolkit/cartSlice'
import { searchClient, startFacetFilters, priceLineID, pricelineIDRecommended, meilisearchUrl } from '../config.js'
import { CapacityString, PriceFormat, FormatDate, GetHistoryData, GetGlobalBannerData, LangFix, ShowLoader, HideLoader, returnItemExtrafields, setCartIdsToStorage, onBannerView, returnProductDataForRedux } from '../functions'
import { getRequestOptions, returnErrorDuringAddProductToCart, getMeilisearchIndexName } from '../helpers/apiHelper'

import { IconProduct } from './IconProduct'
import { IconSuplierDateDelivery } from './IconSuplierDateDelivery'
import { Product } from "./ProductCard"
import { OrderHistoryPopup } from './OrderHistoryPopup'
import LoadableImage from "./LoadableImage/LoadableImage"
import { ProductQtyChangeButtons } from "./MyShoppingCart/ProductQtyChangeButtons"

import { ReactComponent as Brand } from '../assets/svg/ppagebrand.svg'
import { ReactComponent as Ref } from '../assets/svg/ppageref.svg'
import { ReactComponent as Barcode } from '../assets/svg/ppagebarcode.svg'
import { ReactComponent as ShelfLife } from '../assets/svg/ppageshelflife.svg'
import { ReactComponent as QtyInBox } from '../assets/svg/ppageqtyinbox.svg'
import { ReactComponent as New } from '../assets/svg/ppnew.svg'
import { ReactComponent as Booklet } from '../assets/svg/ppbooklet.svg'
import { ReactComponent as Cart } from '../assets/svg/ppcart.svg'
import { ReactComponent as ChevronGreen } from '../assets/svg/chevrongreen.svg'
import { ReactComponent as ChevronRed } from '../assets/svg/chevronred.svg'
import { FavoritesButton } from './FavoritesButton'

import defaultImage from '../assets/images/placeholder.jpg'
import '../assets/css/productpage.css'

const GetVoteData = async productID => {
    const fetchURL = `${process.env.REACT_APP_API_DOMAIN}/items/${productID}/rating-info`
    const response = await fetch(fetchURL,getRequestOptions())
    const json = await response.json()
    return json
}

export const ProductDetails = props => {
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const productsInCart = useSelector(state => state.cartContent.productIDs)
    const productQTYs = useSelector(state => state.cartContent.productQTYs)
    
    const ratingPopupEl = useRef(null)

    const [prodIcons, setProdIcons] = useState([])
    const [productID,setProductID] = useState(props.productID || '')
    const [prodName,setProdName] = useState('')
    const [prodBrand,setProdBrand] = useState('')
    const [prodPrice,setProdPrice] = useState(0)
    const [prodPriceTax,setProdPriceTax] = useState(0)
    const [showOldPrices,setShowOldPrices] = useState(false)
    const [prodPriceOld,setProdPriceOld] = useState(0)
    const [vatGrossPvp,setVatGrossPvp] = useState({})
    const [vatGrossPvpOld,setVatGrossPvpOld] = useState({})
    const [prodRRP,setProdRRP] = useState(0)
    const [markupMargin,setMarkupMargin] = useState({markup: 0, margin: 0})
    const [prodItemID,setProdItemID] = useState(0)
    const [prodBarcode, setProdBarcode] = useState('')
    const [prodNew,setProdNew] = useState(false)
    const [prodBooklet,setProdBooklet] = useState(false)
    const [prodCapacity,setProdCapacity] = useState('')
    const [prodCapacityOld,setProdCapacityOld] = useState('')
    const [prodAvailable,setProdAvailable] = useState(0)
    const [ingredients,setIngredients] = useState('')
    const [nutrients,setNutrients] = useState([])
    const [companyID,setCompanyID] = useState('')
    const [prodHistory,setProdHistory] = useState([])
    const [showStock,setShowStock] = useState(true)
    const [showStockText,setShowStockText] = useState(true)
    const [isOpenHistoryProduct,setIsOpenHistoryProduct] = useState(false)
    const [dataLoaded,setDataLoaded] = useState(false)
    const [productData,setProductData] = useState({})
    const [stockStatus,setStockStatus] = useState('outofstock')
    const [stockText,setStockText] = useState(`${t('niki.b2b.product.outofstock')}`)
    const [productUnitCode,setProductUnitCode] = useState('')
    const [selectedImage,setSelectedImage] = useState(defaultImage)
    const [ratingStatus,setRatingStatus] = useState('rending')
    const [sourceItemTypeId, setSourceItemTypeId] = useState(0)
    const [productValidity, setProductValidity] = useState(0)
    const [productBanners, setProductBanners] = useState([])
    const [similarFacetFilters, setSimilarFacetFilters] = useState(startFacetFilters)
    
    const selectedLanguage = LangFix(localStorage.getItem('locale'))

    const fetchProductData = async () => {
        const filterInsert = `id=${productID}`
        const attributesToRetrieve = `attributesToRetrieve","value":"id,company_id,entity_account_id,tax_group_id,source_id,status_id,attachment_id,source_item_id,source_family_id,source_supplier_id,source_item_type_id,source_barcode_type_id,main_unit_code,sale_unit_code,purchase_unit_code,barcode,matchcode,capacity,capacity_unit_code,width,height,length,netweight,grossweight,properties,order_apply_round,order_round_percent,order_round_unit_code,family,itemStocks,itemPrices.${priceLineID},is_booklet,is_new,itemUnitBarcodeByUnitCodeBox,ratings,attachment,attachments,is_discount,default_warehouse,is_in_stock,itemDescriptions,ingredients,ingredientNutrients,itemRecommendedPrices.${pricelineIDRecommended},itemDiscounts.${priceLineID},kinds,country,id,brand,ItemExtrafieldsAnswers,sale_unit_code_translations`
        const response = await fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("items")}/search?&filter=${encodeURIComponent(filterInsert)}&attributesToRetrieve=${encodeURIComponent(attributesToRetrieve)}`,{
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${process.env.REACT_APP_MEILISEARCH_SECRET_KEY}`
            }
        })
        const json = await response.json()
        return json
    }

    useEffect(() => {
        setShowOldPrices(false)
        setDataLoaded(false)
        fetchProductData()
        .then(json => {
            const data = json.hits.filter(e => e.id === productID)[0]
            if (data) {
                let priceActual = (data.itemPrices && data.itemPrices[priceLineID] && data.itemPrices[priceLineID].unit_price && parseFloat(data.itemPrices[priceLineID].unit_price)) || 0
                let priceActualGross = (data.itemPrices && data.itemPrices[priceLineID] && data.itemPrices[priceLineID].tax_included_price && parseFloat(data.itemPrices[priceLineID].tax_included_price)) || 0
                let priceActualOld = 0
                let priceActualGrossOld = 0
                
                const rrpJson = (data.itemRecommendedPrices && data.itemRecommendedPrices[pricelineIDRecommended] && data.itemRecommendedPrices[pricelineIDRecommended].tax_included_price && parseFloat(data.itemRecommendedPrices[pricelineIDRecommended].tax_included_price)) || 0
                if (data.properties && data.properties.validity_days && parseInt(data.properties.validity_days) > 0) setProductValidity(parseInt(data.properties.validity_days))

                if (data.itemDiscounts && data.itemDiscounts[priceLineID]) {
                    setShowOldPrices(true)
                    priceActualOld = priceActual
                    priceActualGrossOld = priceActualGross
                    priceActual = data.itemDiscounts[priceLineID].unit_price
                    priceActualGross = data.itemDiscounts[priceLineID].tax_included_price
                }
                
                if (rrpJson > 0) {
                    setMarkupMargin({
                        markup: Math.round((rrpJson-priceActualGross)/priceActualGross*100),
                        margin: Math.round((rrpJson-priceActualGross)/rrpJson*100)
                    })
                }
                
                let productImagesArray = []
                data.attachments && data.attachments.forEach(el => productImagesArray.push(el))
                data.attachment && setSelectedImage(data.attachment)
                setProductData({
                    name: data.matchcode,
                    images: productImagesArray,
                    brand: data.brand?.name || '',
                    itemID: data.source_item_id,
                    price: priceActual,
                    uni: data.sale_unit_code_translations[selectedLanguage],
                    qtyinbox: parseInt(data.itemUnitBarcodeByUnitCodeBox?.coefficient) || 1,
                    country: (data.country?.iso_3166_2.toLowerCase()) || null,
                    new: data.is_new,
                    booklet: data.is_booklet,
                    available: (data.itemStocks && data.itemStocks[0] && data.itemStocks[0].available && parseInt(data.itemStocks[0].available)) || 0,
                    barcode: data.barcode,
                    rating: data.ratings.avg_value || 0,
                    ratingVoices: data.ratings.count || 0,
                    product_country: data.country?.data?.iso_3166_2 || data.country?.iso_3166_2,
                    sale_unit_code: data.sale_unit_code,
                })

                setProdName(data.itemDescriptions[selectedLanguage]?.short_description || data.matchcode)
                setProdItemID(data.source_item_id)
                setSourceItemTypeId(data.source_item_type_id)
                setProdBarcode(data.barcode)
                setProdNew(data.is_new)
                setProdBooklet(data.is_booklet)
                setCompanyID(data.company_id)
                data.itemStocks && data.itemStocks && data.itemStocks[0] && data.itemStocks[0].available && setProdAvailable(data.itemStocks[0].available)
                
                setProdPrice(priceActual)
                setProdPriceTax(priceActualGross)
                setProdPriceOld(priceActualOld)
                setProdRRP(rrpJson)
                
                setVatGrossPvp({
                    gross: priceActualGross,
                    vat: (priceActualGross-priceActual),
                    pvp: 0
                })
                setVatGrossPvpOld({
                    gross: priceActualGrossOld,
                    vat: priceActualGrossOld - priceActualOld,
                    pvp: 0
                })
                setProdCapacity(CapacityString(data.capacity,data.capacity_unit_code,priceActual))
                setProdCapacityOld(CapacityString(data.capacity,data.capacity_unit_code,priceActualOld))

                setProdBrand(data.brand?.name || '')
                
                data.ingredients[0]?.ingredients && setIngredients(data.ingredients[0].ingredients)

                data.ingredientNutrients?.forEach(e => setNutrients(oldArray => [...oldArray, {name: e.description, value: e.value}]))

                const ProductAvailable = (data.itemStocks && data.itemStocks[0] && data.itemStocks[0].available && parseInt(data.itemStocks[0].available)) || 0
                setProductUnitCode(data?.sale_unit_code_translations[selectedLanguage] ? data.sale_unit_code_translations[selectedLanguage] : data.sale_unit_code)

                if (parseInt(localStorage.getItem('show.stock')) === 0) {
                    setShowStock(false)
                } else if (ProductAvailable > 20) {
                    setStockStatus('instock')
                    setStockText(t('niki.b2b.allproducts.instock'))
                } else if (ProductAvailable > 0) {
                    setStockStatus('low')
                    setStockText(t('niki.b2b.product.stocklow'))
                }
                
                if (parseInt(localStorage.getItem('show.stock')) === 0 || parseInt(localStorage.getItem('show.stock')) === 1) setShowStockText(false)

                if (data.ItemExtrafieldsAnswers) {
                    const productIconsArray = []
                    productIconsArray.push(!!data.ItemExtrafieldsAnswers ? returnItemExtrafields(data.ItemExtrafieldsAnswers) : { label: '', icon: '' })
                    setProdIcons(productIconsArray)
                }
              
                data.family && setSimilarFacetFilters([...startFacetFilters, `family="${data.family}" AND id!="${productID}"`])

                if (GetHistoryData(productID)) {
                    const productHistoryFullUser = GetHistoryData(productID)
                    setProdHistory([])
                    productHistoryFullUser.forEach(e => {
                        const tempObj = {
                            date: e.created_at,
                            units: e.quantity,
                        }
                        setProdHistory(prevState => ([...prevState, tempObj]))
                    })
                }
                document.querySelector('.product_details__wrapper').scrollTo({top: 0, behavior: 'smooth'})
            }
        })
            .finally(() => setDataLoaded(true))
        // eslint-disable-next-line
    }, [productID])

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
            e.content_subtype_id === 'gOkj31nzQol9e5EQ' && e.contentType?.data?.real_id === 6 && setProductBanners(GetGlobalBannerData(e))
        })

        const body = document.body;
        const originalStyle = body.style.overflow;
        body.style.overflow = 'hidden';

        return (() => {
            body.style.overflow = originalStyle
            localStorage.setItem('url.back', '')
            localStorage.setItem('is_on_product_card', 'false')
        })
    }, [])

    useEffect(() => {
        setProductID(props.productID)
    }, [props.productID])

    const similarHit = ({ hit }) => {
        return (
            <Product key={`product${hit.id}`} {...hit} isGrid={true} onProductClick={(id) => setProductID(id)}/>
        )
    }

    const AccordionTitleClick = e => e.target.parentNode.classList.toggle('active')

    const ChangeSelectedImage = e => {
        const thisElementData = e.target.dataset
        setSelectedImage(thisElementData.image)
    }

    const CartClick = () => {
        if (localStorage.getItem('cart.uuid')) {
            const uuid = localStorage.getItem('cart.uuid')
            ShowLoader()
            const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
            requestOptionsCart.body = JSON.stringify({
                cart_header_uuid: uuid,
                item_id: productID,
                matchcode: prodName,
                variation_id: '3',
                qty_in_box: productData.qtyinbox.toString(),
                qty: productData.qtyinbox.toString(),
                unit_price: prodPrice.toString(),
                tax_included_price: prodPriceTax.toString()
            })
            
            fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details?include=item`,requestOptionsCart)
            .then(response => response.json())
            .then(json => {
                if (json?.message) {
                    returnErrorDuringAddProductToCart(json.message, dispatch)
                } else if (json.data) {
                    const {id, product_item_id} = json.data
                    dispatch(addCartProduct(returnProductDataForRedux(id, prodName, productData.qtyinbox, productData.qtyinbox, productData.uni, prodPrice, productID, prodItemID, product_item_id)))
                }
            })
            .catch(error => returnErrorDuringAddProductToCart(error, dispatch))
            .finally(() => HideLoader())
        } else {
            let detaildata = []
            detaildata[0] = {
                matchcode: prodName,
                variation_id: '1',
                qty: productData.qtyinbox.toString(),
                unit_price: prodPrice.toString(),
                tax_included_price: prodPriceTax.toString(),
                qty_in_box: productData.qtyinbox.toString(),
                item_id: productID
            }
            const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
            requestOptionsCart.body = JSON.stringify({
                company_id: companyID,
                customer_account_id: localStorage.getItem('account.entity.id'),
                status_id: 'J8RvMwowm7k2pNOr',
                details: detaildata,
                realKey: '1',
            })
            
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-headers`,requestOptionsCart)
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    const {uuid, id_real, id, product_item_id} = json.data
                    setCartIdsToStorage(uuid, id_real, id)
                    dispatch(addCartProduct(returnProductDataForRedux(id, prodName, productData.qtyinbox, productData.qtyinbox, productData.uni, prodPrice, productID, prodItemID, product_item_id)))
                }   
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }

    const ShowRatingPopup = () => ratingPopupEl.current.classList.add('active')
    const CloseRatingPopup = () => ratingPopupEl.current.classList.remove('active')
    const StarRatingClick = e => {
        const ratingVote = e.target.value
        const ratingURL = `${process.env.REACT_APP_API_DOMAIN}/items/${productID}/ratings`
        const requestOptionsPostRating = getRequestOptions('POST', null, 'application/json')
        requestOptionsPostRating.body = JSON.stringify({
            value: ratingVote,
            comment: '',
        })
        
        ShowLoader()
        fetch(ratingURL,requestOptionsPostRating)
        .then(response => {
            response.status === 200 && setRatingStatus('success')
            ShowLoader()
            GetVoteData(productID)
            .then(json => {
                json.data && setProductData({
                    ...productData,
                    rating: json.data.average,
                    ratingVoices: json.data.count,
                })
            })
            return response.json()
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    const OpenHistoryPopup = () => {
        setIsOpenHistoryProduct(true)
    }

    const [productImgIsValid, setProductImgIsValid] = useState(selectedImage?.endsWith("g"));
    
    const [isLabelVisible, setIsLabelVisible] = useState('')
  
    const showLabel = (val) => setIsLabelVisible(val)
    const hideLabel = () => setIsLabelVisible('')
    const isSuplier = sourceItemTypeId === 98 || sourceItemTypeId === 99

    const hideProductModal = () => {
        props.setProductId('')
    }

    return (
        <div className='product_details__wrapper'>
        <div className="product_details__popup">
            {dataLoaded
                ? <div>
                    <div className="product_page__content">
                        <div className="product_page__rating__icons">
                            <button className="show_rating_popup" onClick={ShowRatingPopup}>
                                <span className="rating_placeholder">
                                    <span className="rating_value" style={{ width: `${productData.rating}em`}}></span>
                                </span>
                                <span className="rating_votes">({productData.ratingVoices} {t('niki.b2b.product.votes')})</span>
                            </button>
                            <div>
                                {isSuplier && <IconSuplierDateDelivery prodPage={true} />}
                            </div>
                            <button className="btn btn-primary" type="button" onClick={hideProductModal}>{t('niki.b2b.returnproducts.close')}</button>
                        </div>
                        <h1>{prodName}</h1>
                        <div className="product_page__product__icons">
                        <FavoritesButton productId={productID} details={true}/>
                            {prodIcons.length > 0 && prodIcons.map((e) => e && <IconProduct icon={e} key={e.id} index={e.id}/>)}
                            <div className={[`wrapper__info_product_icons`, !prodIcons?.[0] && `wrapper__info_prod_icons_not`].join(' ')}>
                                {productData?.product_country && <div className={[`country_badge_1`, `flag-icon-${productData.product_country.toLowerCase()}`].join(' ')}></div>}
                                {prodNew && <div className="product_page__new"><New /></div>}
                                {prodBooklet && <div className="product_page__booklet"><Booklet /></div>}
                            </div>
                        </div>
                        <div className="product_page__images">
                            <picture className="product_page__main_image">
                                {productImgIsValid
                                    ? <>
                                    <source srcSet={selectedImage} type="image/jpeg" />
                                        <img src={selectedImage} alt={prodName} onError={() => setProductImgIsValid(false)} loading="lazy" />
                                    </>
                                    : <>
                                        <source srcSet={defaultImage} type="image/jpeg" />
                                        <img src={defaultImage} alt={prodName} loading="lazy" />
                                    </>
                                }                
                            </picture>
                            <div className="product_page__additional_images">{productData.images && productData.images.map((e,i)=>
                                <img
                                    key={`productimage${i}`}
                                    src={e}
                                    alt={prodName}
                                    onClick={ChangeSelectedImage}
                                    loading="lazy"
                                    data-image={e}
                                />
                            )}</div>
                        </div>

                        <div className={[`all_about_price`, showOldPrices && `all_about_price_with_discount`].join(' ')}>
                            {showOldPrices &&
                                <div className="all_about_price_discount">-{Math.round((prodPriceOld-prodPrice)/prodPriceOld*100)}%</div>
                            }
                            <div className="all_about_price__three">
                                <div className="all_about_price__three__single">
                                    <div className="aap_unit aap_unit__border_bottom">
                                        <div className="aap_price">{PriceFormat(prodPrice)}</div>
                                        <div className="aap_capacity">{prodCapacity}</div>
                                    </div>
                                    <div className="aap_unit">
                                        <div className="aap_vat_gros marginbottom5">
                                            <strong>{t('niki.b2b.product.vat')} {Math.round(vatGrossPvp.vat/prodPrice*100)}%</strong>
                                            <span>{PriceFormat(vatGrossPvp.vat)}</span>
                                        </div>
                                        <div className="aap_vat_gros">
                                            <strong>{t('niki.b2b.product.gross')}</strong>
                                            <span>{PriceFormat(vatGrossPvp.gross)}</span>
                                        </div>
                                    </div>
                                </div>
                                {showOldPrices &&
                                    <div className="all_about_price__three__single aap_single_old">
                                        <div className="aap_unit aap_unit__border_bottom">
                                            <div className="aap_price">{PriceFormat(prodPriceOld)}</div>
                                            <div className="aap_capacity">{prodCapacityOld}</div>
                                        </div>
                                        <div className="aap_unit">
                                            <div className="aap_vat_gros marginbottom5">
                                                <strong>{t('niki.b2b.product.vat')} {Math.round(vatGrossPvpOld.vat/prodPriceOld*100)}%</strong>
                                                <span>{PriceFormat(vatGrossPvpOld.vat)}</span>
                                            </div>
                                            <div className="aap_vat_gros">
                                                <strong>{t('niki.b2b.product.gross')}</strong>
                                                <span>{PriceFormat(vatGrossPvpOld.gross)}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {prodRRP
                                    ? <div className="all_about_price__three__single">
                                        <div className="aap_unit aap_unit__border_bottom">
                                            <div className="aap_rrp">{t('niki.b2b.product.pvp')}</div>
                                            <div className="aap_rrp_value">{PriceFormat(prodRRP)}</div>
                                        </div>
                                        <div className="aap_unit app_mm">
                                            <div className={["container_icon_product", "container_icon_product_up"].join(' ')} onMouseOver={() => showLabel('up')} onMouseOut={hideLabel}>
                                                <label className={isLabelVisible === 'up' ? "" : "hidden_allproducts"}> {t('niki.b2b.product.markup')}</label> 
                                                <ChevronGreen />{markupMargin.markup}%
                                            </div>
                                            <div className={["container_icon_product", "container_icon_product_down"].join(' ')} onMouseOver={() => showLabel('down')} onMouseOut={hideLabel}>
                                            <label className={isLabelVisible === 'down' ? "" : "hidden_allproducts"}> {t('niki.b2b.product.margin')}</label> 
                                                <ChevronRed />{markupMargin.margin}%
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="aap_history_cart">
                                <div className="aap_history">
                                    {prodHistory.length > 0
                                        ? <button onClick={OpenHistoryPopup} className={[`aap_history__inner`, prodHistory.length === 1 ? 'only_one' : null].join(' ')}>
                                            {prodHistory.filter((e, i) => i === 0 || i === 1).map((e, i) => <div key={`orderhistoryunit${i}`}>{FormatDate(e.date)} &mdash; {e.units} {productData.uni}</div>)}
                                        </button>
                                        : <span className="aap_no_history">{t('niki.b2b.product.noorderhistory')}</span>
                                    }
                                </div>
                            </div>
                            <div className="flex_end">
                                {productsInCart.includes(productID)
                                    ? <ProductQtyChangeButtons productid={productID} qty={productQTYs[productID]} qtyinbox={productData.qtyinbox} sale_unit_code={productData.sale_unit_code} />
                                    : <button className="btn btn-primary btn-cart" onClick={CartClick}><Cart />+</button>
                                }
                            </div>
                        </div>

                        <table className="specifications">
                            <tbody>
                                <tr>
                                    <td>
                                        <Brand />
                                    </td>
                                    <td>
                                        <strong>{t('niki.b2b.product.brand')}</strong>
                                        {prodBrand}
                                    </td>
                                    <td>
                                        <ShelfLife />
                                    </td>
                                    <td>
                                        <strong>{t('niki.b2b.productpage.shelflife')}</strong>
                                        {productValidity} {t('niki.b2b.debts.days')}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Ref />
                                    </td>
                                    <td>
                                        <strong>{t('niki.b2b.cart.ref')}</strong>
                                        {prodItemID}
                                    </td>
                                    <td>
                                        <QtyInBox />
                                    </td>
                                    <td>
                                        <strong>{t('niki.b2b.product.in_box')}</strong>
                                        {productData.qtyinbox} {productUnitCode} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Barcode />
                                    </td>
                                    <td>
                                        <strong>{t('niki.b2b.product.barcode')}</strong>
                                        {prodBarcode}
                                    </td>
                                    {showStock
                                        ? <>
                                            <td>
                                                <span className={[`specifications_available`,stockStatus].join(' ')}></span>
                                            </td>
                                            {showStockText
                                                ? <td className={stockStatus}>{+prodAvailable === 0 ? stockText : `${prodAvailable} ${productUnitCode}`}</td>
                                                : <td className={stockStatus}>{stockText}</td>
                                            }
                                        </>
                                        : <>
                                            <td></td>
                                            <td></td>
                                        </>
                                    }
                                </tr>
                            </tbody>
                        </table>

                        {(productBanners && productBanners.length > 0) && productBanners.map((e,i) => 
                            <div key={`productbanner${i}`} className="product_page__banner" onClick={() => onBannerView(e, navigate)}> 
                                <LoadableImage src={e.image} alt={e.title} height={'132px'}/>
                            </div>
                        )}
                    </div>
                </div>
                : <div className="product_page__placeholder">
                    <div className="product_page__placeholder__breadcrumbs">
                        <div className="animated_placeholder"></div>
                        <div className="animated_placeholder"></div>
                        <div className="animated_placeholder"></div>
                    </div>
                    <div className="product_page__placeholder__h1 animated_placeholder"></div>
                    <div className="product_page__placeholder__main_image animated_placeholder"></div>
                    <div className="product_page__placeholder__images">
                        <div className="animated_placeholder"></div>
                        <div className="animated_placeholder"></div>
                        <div className="animated_placeholder"></div>
                    </div>
                    <div className="product_page__placeholder__price_area animated_placeholder"></div>
                </div>
                }
                <div className="product_page__accordion">
                        {ingredients && ingredients.length > 0 &&
                            <div className="accordion active">
                                <div className="accordion__title" onClick={AccordionTitleClick}>{t('niki.b2b.productpage.ingredientsallergens')}</div>
                                <div className="accordion__content"><p>{ingredients}</p></div>
                            </div>
                        }
                        {nutrients && nutrients.length > 0 &&
                            <div className="accordion">
                                <div className="accordion__title" onClick={AccordionTitleClick}>{t('niki.b2b.productpage.nutritionalinformation')}</div>
                                <div className="accordion__content">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>{t('niki.b2b.productpage.nutrient')}</th>
                                                <th>{t('niki.b2b.cart.value')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {nutrients.map((e,i) =>
                                            <tr key={`nutrient${i}`}>
                                                <td>{e.name}</td>
                                                <td>{e.value}</td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                </div>

                    <div className="similar_products">
                        <h2 className="center">{t('niki.b2b.productpage.similarproducts')}</h2>
                        <div className="products_grid paddingbottom0">
                            <InstantSearch
                                indexName={getMeilisearchIndexName("items")}
                                searchClient={searchClient}
                            >
                                <Configure
                                    facetFilters={similarFacetFilters}
                                    hitsPerPage={12}
                                    attributesToHighlight={[]}
                                    attributesToRetrieve={['id','ItemExtrafieldsAnswers','main_unit_code_translations','sale_unit_code_translations','company_id','entity_account_id','tax_group_id','source_id','status_id','attachment_id','source_item_id','source_family_id','source_supplier_id','source_item_type_id','source_barcode_type_id','main_unit_code','sale_unit_code','purchase_unit_code','barcode','matchcode','capacity','capacity_unit_code','width','height','length','netweight','grossweight','properties','order_apply_round','order_round_percent','order_round_unit_code','family','itemStocks',`itemPrices.${priceLineID}`,`itemDiscounts.${priceLineID}`,'is_booklet','is_new','itemUnitBarcodeByUnitCodeBox','ratings','attachment','attachments','is_discount','default_warehouse','is_in_stock','itemDescriptions','brand','country']}
                                />
                                <Hits hitComponent={similarHit} />
                            </InstantSearch>
                        </div>
                    </div>

            <div className="product_page__rating_popup" ref={ratingPopupEl}>
                <div className="product_page__rating_popup__content">
                    <div className="product_page__rating_popup__content__product_name">{t('niki.b2b.vote.whatdoyouthink')}<br />{prodName}</div>
                    <div className="product_page__rating_popup__content__product_image"><img src={selectedImage} alt={prodName} loading="lazy" /></div>

                    <div className="star-rating">
                        <input type="radio" name="rating" value="1" data-id={productID} onClick={StarRatingClick} /><i></i>
                        <input type="radio" name="rating" value="2" data-id={productID} onClick={StarRatingClick} /><i></i>
                        <input type="radio" name="rating" value="3" data-id={productID} onClick={StarRatingClick} /><i></i>
                        <input type="radio" name="rating" value="4" data-id={productID} onClick={StarRatingClick} /><i></i>
                        <input type="radio" name="rating" value="5" data-id={productID} onClick={StarRatingClick} /><i></i>
                    </div>

                    <div className="product_page__rating_popup__content__register">{t('niki.b2b.vote.registervote')}.</div>
                    {ratingStatus === 'success' && <div className="product_page__rating_popup__content__alert">{t('niki.b2b.vote.voted')}</div>}
                    <div className="product_page__rating_popup__content__current_vote_results">{t('niki.b2b.vote.results')}</div>
                    <div>
                        <span className="rating_placeholder on_popup"><span className="rating_value" style={{ width: `${productData.rating}em`}}></span></span>
                        <div className="product_page__rating_popup__content__current_vote_results_votes">({productData.ratingVoices} {t('niki.b2b.product.votes')})</div>
                    </div>
                    <div className="product_page__rating_popup__close" onClick={CloseRatingPopup}></div>
                </div>
                <div className="product_page__rating_popup__overlay" onClick={CloseRatingPopup}></div>
            </div>

            {prodHistory.length > 0 && isOpenHistoryProduct &&
                <OrderHistoryPopup
                    matchcode={prodName}
                    imgsrc={selectedImage}
                    sale_unit_code={productData.uni}
                    qty_in_box={productData.qtyinbox}
                    item_id={prodItemID}
                    product_id={productID}
                    setIsOpenHistoryProduct={setIsOpenHistoryProduct}
                />
            }
         </div>
    </div>
    )
}