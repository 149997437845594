import { useState, useEffect, useRef, useMemo } from "react"
import { connectInfiniteHits } from "react-instantsearch-dom"

import { Product, ProductPlaceholder } from "./ProductCard"

export const CustomInfiniteHits = connectInfiniteHits(({ hits, hasMore, refineNext, setClickFavorites, setIsProductDisplay, onProductClick}) => {
    const favorites = localStorage.getItem('favorites')
    const histories =  localStorage.getItem('history.ids')   
    const loaderRef = useRef(null)
    const search = window.location.search
    
    const [isFetching, setIsFetching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
  
    useEffect(() => {
        const handleScroll = () => {
            if (!hasMore || isFetching) return
            if (loaderRef.current && loaderRef.current.getBoundingClientRect().top <= window.innerHeight * 3) {
                setIsLoading(true)
                setIsFetching(true)
            }
        }
        
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
        // eslint-disable-next-line
    }, [hits, isFetching, hasMore])
   
    
    useEffect(() => {
        if (hits.length > 0) {
            if (document.querySelector('.products_grid__product')) {
                setIsProductDisplay(hits.length)
                setIsLoading(false)
            }
        }
    }, [hits.length, setIsProductDisplay])

    useEffect(() => {
        if (!isFetching) return
        refineNext()
        setIsFetching(false)
        // eslint-disable-next-line
    }, [isFetching])

    const hitsMemo = useMemo(() => {
        return (
            <div className={`products_grid paddingbottom0 ${localStorage.getItem('product.view')}`}>
                {hits.map(hit => {
                        if(search && (search.includes('favorites') || search.includes('purchased'))){
                            if (search.includes('favorites')) {
                                return favorites.includes(hit.id) && <Product key={`product${hit.id}`} {...hit} setClickFavorites={setClickFavorites} onProductClick={onProductClick}/>
                            } else {
                                return histories.includes(hit.id) && <Product key={`product${hit.id}`} {...hit} setClickFavorites={setClickFavorites} onProductClick={onProductClick}/>
                            }
                        } else {
                            return  <Product key={`product${hit.id}`} {...hit} setClickFavorites={setClickFavorites} onProductClick={onProductClick}/>
                        } 
                    }  
                )}
            </div>
        )
        // eslint-disable-next-line
    }, [hits.length])

    return (
        <>
            {hitsMemo}
            <div ref={loaderRef}></div>
            {isLoading && <div className={`products_grid paddingbottom0 ${localStorage.getItem('product.view')}`}>
                    <ProductPlaceholder elements={12} />
                </div>
            }
        </>
    )
})
