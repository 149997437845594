import React, { useEffect, useState, useRef } from "react"
import { t } from "i18next"
import { ResetCart, getBannerDataLogin, ShowLoader, HideLoader } from "../functions.js"
import { applyAccount } from './loginFunctions'
import { LoginHeader } from './LoginHeader'
import { ReactComponent as LoginSubmit } from '../assets/svg/loginpagesubmit.svg'

import "../assets/css/login.css"

export const SelectAccount = () => {
    const [data, setData] = useState([])
    const [selectedEntity, setSelectedEntity] = useState({})
    const [bannerTwo, setBannerTwo] = useState({})
    const [initialData, setInitialData] = useState([])
    const [accountName, setAccountName] = useState('')

    const inputRef = useRef(null);

    const selectEntity = e => {
        const elementDataset = e.target.dataset
        setSelectedEntity({
            id: elementDataset.id,
            accountName: elementDataset.accountname,
        })
        setAccountName(elementDataset.accountname)
        setData(initialData)
        document.querySelector('.login__select_account').classList.remove('active')
    }
    
    const SelectEntityClick = () => {
        document.querySelector('.login__select_account').classList.add('active')
        const currentElement = document.querySelector('li.current');
       
        if (currentElement) {
            currentElement.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            })
        }
    }

    const onChangeInput = (event) => {
        setAccountName(event.target.value)
        if (!event.target.value) {
            setData(initialData)
            return
        } else {
            const accountsFilter = initialData.filter(ac => ac.accountName.toLowerCase().includes(event.target.value.toLowerCase()))
            setData(accountsFilter)
        }
    }

    const enterShop = () => {
        if (localStorage.getItem('user.id.old') && localStorage.getItem('user.id.old') !== localStorage.getItem('user.id')) ResetCart()
        applyAccount(selectedEntity.id)
    }

    useEffect(()=>{
        ShowLoader()
        const accounts = JSON.parse(localStorage.getItem('entity_accounts'))
        const accountData = []
        accounts.forEach(account => accountData.push({ ...account, accountName: account.external_id + ' - ' + account.matchcode }))
        setData(accountData)
        setInitialData(accountData)
        if (accountData.length) {
            setSelectedEntity({
                id: accountData[0].id,
                accountName: accountData[0].accountName,
            })
            setAccountName(accountData[0].accountName)
        }

        const data = JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
            e.content_subtype_id === 'J8RvMwowm7k2pNOr' && e.contentType?.data?.real_id === 1 && setBannerTwo(getBannerDataLogin(e))
        })
        HideLoader()
    }, [])
    
    const handleClearClick = () => {
        setAccountName('');
        SelectEntityClick();
        setData(initialData);
        inputRef.current.focus();
    };

    return (
        <>
            <div className="login__wrapper">
                <LoginHeader />
                <div className="login__content">
                    <div className="login__header__title">{t('niki.b2b.login.selectaccount')}</div>
                    <div className="please_select_account">{t('niki.b2b.account.hello')} {localStorage.getItem('user_name')}. {t('niki.b2b.login.selectaccountplease')}.</div>
                    
                        <>
                            <div className="login__select_account">
                                <input className="login__select_account__current"
                                    type="text"
                                    value={accountName}
                                    placeholder={t('niki.b2b.login.selectaccount')}
                                    onClick={SelectEntityClick}
                                    onChange={(e) => onChangeInput(e)}
                                    ref={inputRef}
                                />
                                 <span
                                    className="clear-button"
                                    onClick={handleClearClick}
                                    style={{ display: accountName ? 'inline-block' : 'none' }}
                                >
                                    ×
                                </span>
                                {/* <div className="login__select_account__current" onClick={SelectEntityClick}>
                                    {selectedEntity.accountName}
                                </div> */}
                                <ul className="login__select_account__list">
                                {data.map((e,i)=>
                                    <li
                                        key={`entityaccounts${i}`}
                                        onClick={selectEntity}
                                        data-id={e.id}
                                        data-accountname={e.accountName}
                                        className={e.accountName === accountName ? 'current' : null}
                                    >{e.accountName}</li>
                                )}
                                </ul>
                            </div>
                            <div className="select_account__submit"><button className="btn" type="button" onClick={enterShop}>{t('niki.b2b.login.enter')}<LoginSubmit /></button></div>
                        </>
                </div>
                <a href={bannerTwo.url} target={bannerTwo.target} rel="noopener noreferrer" className="login__banner"><img src={bannerTwo.image} alt={bannerTwo.title} /></a>
            </div>
            <div className="loader"></div>
        </>
    )
}