import { createSlice } from "@reduxjs/toolkit"

const allProductsSlice = createSlice({
    name: 'allproducts',
    initialState: {
        page: 1,
        h1: 'niki.b2b.menu.allproducts',
        isClickOnAllProductsInMainMenu: false,
        isOpenNoCartAlert: false,
        isOpenProductNotFoundAlert: false,
        errorMessage: '',
        isOpenModalCreateNewCart: false,
        isRefreshMarketingCampaigns: false,
        isReadyFavorites: false,
    },
    reducers: {
        setPage(state,action) {
            state.page = action.payload
        },
        resetPage(state) {
            state.page = 1
        },
        setIsClickOnAllProductsInMainMenu(state,action) {
            state.isClickOnAllProductsInMainMenu = action.payload
        },
        setIsOpenNoCartAlert(state,action) {
            state.isOpenNoCartAlert = action.payload
        },
        setIsOpenProductNotFoundAlert(state,action) {
            state.isOpenProductNotFoundAlert = action.payload
        },
        setErrorMessage(state,action) {
            state.errorMessage = action.payload
        },
        setIsOpenModalCreateNewCart(state,action) {
            state.isOpenModalCreateNewCart = action.payload
        },
        setIsRefreshMarketingCampaigns(state, action) {
            state.isRefreshMarketingCampaigns = action.payload
        },
        setIsReadyFavorites(state, action) {
            state.isReadyFavorites = action.payload
        },
    }
})

export default allProductsSlice.reducer
export const { setPage, resetPage, setIsClickOnAllProductsInMainMenu, setIsOpenNoCartAlert, setIsOpenProductNotFoundAlert, setErrorMessage, setIsOpenModalCreateNewCart, setIsRefreshMarketingCampaigns, setIsReadyFavorites} = allProductsSlice.actions