import React, { useRef, useEffect, useState, useCallback }  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { changeDeliveryMethod, changePaymentMethod, changeWarehousePickup } from '../reduxToolkit/checkoutSlice.js'
import { clearCartContent, clearCartIdName } from '../reduxToolkit/cartSlice'
import { t } from "i18next"
import { getRequestOptions, getAccountBlocked } from '../helpers/apiHelper'
import { ShowLoader, HideLoader, PriceFormat } from '../functions'
import { priceLineID, companySupplier } from '../config.js'

import { BreadCrumbs } from "../components/BreadCrumbs"
import { AlertPopup } from "../components/AlertPopup"
import DeliveryMethod from "../components/checkout/DeliveryMethods"
import DeliveryAddress from "../components/checkout/DeliveryAddress"
import { returnGroupingSuppliers, returnGroupingDelivery } from "../components/checkout/functions"
import { PaymentMethod, AdditionalNotesContent} from "../components/checkout/PaymentMethod"

import { ReactComponent as ArrowLeft } from '../assets/svg/deliveryarrowleft.svg'
import { ReactComponent as ArrowRight } from '../assets/svg/deliveryarrowright.svg'

import './../assets/css/checkout.css'

export const Checkout = () => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const deliveryMethod = useSelector(state => state.checkoutContent.deliveryMethod)
    const additionalNotesText = useSelector(state => state.checkoutContent.additionalNotes)
    const paymentMethod = useSelector(state => state.checkoutContent.paymentMethod)
    const warehousePickup = useSelector(state => state.checkoutContent.warehousePickup)
    const startDate = new Date(useSelector(state => state.checkoutContent.startDate))
    const startDateWarehouse = new Date(useSelector(state => state.checkoutContent.startDateWarehouse))
    const dataCartDocumentRequest = useSelector(state => state.checkoutContent.dataCartDocumentRequest)

    const addressesEl = useRef(null)
    const deleveryMethodEl = useRef(null)
    const paymentMethodEl = useRef(null)
    const additionalNotesEl = useRef(null)
    const deliveryPopupEl = useRef(null)

    const [step, setStep] = useState('delivery')
    const [cartIDReal, setCartIDReal] = useState('')
    const [loadingStatus, setLoadingStatus] = useState('waiting')
    const [disabled, setDisabled] = useState(false)
    const [cartName, setCartName] = useState('')
    const [isErrorOrderCreation, setIsErrorOrderCreation] = useState(false)
    const [termsData, setTermsData] = useState('')
    const [isShowTermsData, setIsShowTermsData] = useState(false)
    const [suppliersData, setSuppliersData] = useState([])
    const [deliveriesData, setDeliveriesData] = useState([])
    const [qtyDelivery, setQtyDelivery] = useState(0)
    const [isAccountBlocked, setIsAccountBlocked] = useState(false)
    const [isAccountBlockedModal, setIsAccountBlockedModal] = useState(false)

    const ExplandCollapseAdresses = () => addressesEl.current.classList.toggle('active')
    const ExplandCollapseDelivery = () => deleveryMethodEl.current.classList.toggle('active')
    const ExplandCollapsePayment = () => paymentMethodEl.current.classList.toggle('active')
    const ExplandCollapseAdditionalNotes = () => additionalNotesEl.current.classList.toggle('active')
    const ShowPopup = () => deliveryPopupEl.current.classList.add('active')
    const HidePopup = () => deliveryPopupEl.current.classList.remove('active')

    useEffect(() => {
        try {
            getAccountBlocked().then(json => {
                typeof json?.blocked === 'boolean' && setIsAccountBlocked(!!json?.blocked)
        })
        } catch(error) {
            console.error(error)
        }
    }, [])

    useEffect(() => {
        isAccountBlocked && setIsAccountBlockedModal(true)
    }, [isAccountBlocked])

    /* scroll direction */
    const [y,setY] = useState(window.scrollY)
    const handleNavigation = useCallback(e => {
        const window = e.currentTarget
        y > window.scrollY ? document.body.classList.add('fixed_bottom') : document.body.classList.remove('fixed_bottom')
        setY(window.scrollY)
    },[y])

    useEffect(() => {
        setY(window.scrollY)
        window.addEventListener("scroll", handleNavigation)
        return () => {
            window.removeEventListener("scroll", handleNavigation)
        }
    }, [handleNavigation])
    /* scroll direction */
    
    const GoToPayment = () => {
        setStep('payment')
        addressesEl.current.classList.contains('active') && addressesEl.current.classList.remove('active')
        deleveryMethodEl.current.classList.contains('active') && deleveryMethodEl.current.classList.remove('active')
        window.scrollTo(0, 0)
    }

    const ModifyDelivery = () => {
        setStep('delivery')
        !addressesEl.current.classList.contains('active') && addressesEl.current.classList.add('active')
        !deleveryMethodEl.current.classList.contains('active') && deleveryMethodEl.current.classList.add('active')
        window.scrollTo(0, 0)
    }

    const AcceptTerms = () => {
        let bodyRequest = {
            carrier_id: '1',
            payment_id: paymentMethod,
            footer_message: additionalNotesText,
        }

        if (+deliveryMethod === 1) {
            if (dataCartDocumentRequest.length > 0) {
                bodyRequest.split_delivery_at = dataCartDocumentRequest
                bodyRequest.entity_address_id = localStorage.getItem('address.id.real')
                bodyRequest.split_by = 'drop_cross_mix'
                // split_by: 'dropshipping',    
            } else {
                bodyRequest.delivery_at = startDate
                bodyRequest.entity_address_id = localStorage.getItem('address.id.real')
            }   
        } else {
            bodyRequest.delivery_at = startDateWarehouse
            bodyRequest.warehouse_pickup_id = warehousePickup
        }
        
        const requestOptions = getRequestOptions('POST', null, 'application/json')
        requestOptions.body = JSON.stringify(bodyRequest)
        setDisabled(true)
        setLoadingStatus('loading')
        let nextStep = '/thankyou?'
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-to-document/${localStorage.getItem('cart.uuid')}`,requestOptions)
        .then(response => response.json())
        .then(json => {
            if (json.length > 0) {
                let params = 'orderIds='
                json.forEach(u => params+=u.uuid+',')
                nextStep += params.slice(0,params.length-1)
                localStorage.removeItem('cart.uuid')
                localStorage.removeItem('cart.id')
                localStorage.removeItem('cart.id.real')
                dispatch(clearCartContent())
                dispatch(clearCartIdName())
                // window.location.href = nextStep
                navigate(nextStep)
            } else {
                setIsErrorOrderCreation(true)
            }
        })
        .catch(error => {
            setIsErrorOrderCreation(true)
            console.error(error)
        })
        .finally(() => {
            setLoadingStatus('waiting')
            setDisabled(false)
            HideLoader()
        })
    }

    const createDeliveryData = (data) => {
        let totalCount = 0
        let totalSum = 0
        let totalVat = 0
        let totalSumNet = 0
        data.forEach(e => {
            let price = (e.item && e.item.data && e.item.data.itemPrices && e.item.data.itemPrices.data && e.item.data.itemPrices.data[0] && parseFloat(e.item.data.itemPrices.data[0].unit_price)) || 0
            if (e.item && e.item.data && e.item.data.itemDiscounts && e.item.data.itemDiscounts.data && e.item.data.itemDiscounts.data[0] && e.item.data.itemDiscounts.data[0].unit_price) {
                price = parseFloat(e.item.data.itemDiscounts.data[0].unit_price)
            }
            const vat = ((parseFloat(e.tax_included_price) - parseFloat(price ? price : e.unit_price)) * e.qty) || 0

            totalSum += +parseFloat(e.qty * (price ? price : e.unit_price) + vat).toFixed(2)
            totalCount += e.qty
            totalVat += vat
            totalSumNet += +parseFloat(e.qty * (price ? price : e.unit_price)).toFixed(2)
        })
        const objSuppl = {
            qty: totalCount,
            sum: parseFloat(totalSum).toFixed(2),
            vat:  parseFloat(totalVat).toFixed(2),
            sum_net: parseFloat(totalSumNet).toFixed(2),
            supplierName: data[0]?.item?.data?.item_type_id !== '5QrvVGorkVn14mAR' && data[0]?.item?.data?.item_type_id !== 'rm0GNla0Q6og35Me' ? companySupplier : `${data[0].item.data.entityAccount.data.external_id}`,
            estimated_delivery: data[0]?.item?.data?.entityAccount?.data?.estimated_delivery ? data[0].item.data.entityAccount.data.estimated_delivery : null,
            entity_account_id: data[0]?.item?.data?.entityAccount?.data?.id || '',
        }
        objSuppl.qty && setSuppliersData(prevState => ([...prevState, objSuppl]))
        !objSuppl.qty && objSuppl.supplierName !== companySupplier && setSuppliersData(prevState => ([...prevState, objSuppl]))
    }

    useEffect(() => {
        const resSet = new Set()
        suppliersData.forEach(i => resSet.add(i.estimated_delivery))
        setQtyDelivery(resSet.size)
        resSet.size > 1 && setDeliveriesData(returnGroupingDelivery(suppliersData))
    }, [suppliersData])

    useEffect(() => {

        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-header/${localStorage.getItem('cart.uuid')}/${localStorage.getItem('account.entity.id')}?realKey=1`,getRequestOptions())
            .then(response => response.json())
            .then(json => {
                setCartIDReal(json.data.id_real)
                setCartName(json.data.matchcode)
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        
        fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details?cart_header_uuid=${localStorage.getItem('cart.uuid')}&include=item,item.itemCategories,item.entityAccount,item.itemDescriptions,item.itemPrices,item.itemDiscounts,item.itemStocks&realKey=true&showVat=true&limit=1000&source_price_id[]=${priceLineID}&rand=${Math.random()}`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                setSuppliersData([])

                const data = json.data;
                const anotherSuppliers = data.filter(i => i?.item?.data?.item_type_id === '5QrvVGorkVn14mAR' || i?.item?.data?.item_type_id === 'rm0GNla0Q6og35Me')
                if (anotherSuppliers.length > 0) {
                    const productsMonolith = data.filter(i => i?.item?.data?.item_type_id !== '5QrvVGorkVn14mAR' && i?.item?.data?.item_type_id !== 'rm0GNla0Q6og35Me')

                    if (anotherSuppliers.length > 0) {
                        createDeliveryData(productsMonolith)
                        // returnGroupingSuppliers(testArr).forEach(arr => createDeliveryData(arr))
                        returnGroupingSuppliers(anotherSuppliers).forEach(arr => createDeliveryData(arr))
                    } else {
                        createDeliveryData(productsMonolith)
                        createDeliveryData(anotherSuppliers)
                    }
                } else {
                    return
                }
            })
            .catch(error => console.error(error))
        
        fetch(`${process.env.REACT_APP_API_DOMAIN}/terms-and-conditions`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                json.data && setTermsData(json.data[0].body)
            })
            .catch(error => console.error(error))
    
        dispatch(changePaymentMethod(localStorage.getItem('payment.id')))
        dispatch(changeDeliveryMethod('1'))
        dispatch(changeWarehousePickup(localStorage.getItem('warehouse.id.real')))
            
        // eslint-disable-next-line
    }, [])

    return (
        <> 
            <div className={isShowTermsData && "hidden"}>
                <div className="breadcrumbs__wrapper">
                    <BreadCrumbs itemlist={[{'url':'/myshoppingcart/','title':t('niki.b2b.cart.shoppingcart')}]} />
                    <ol className={[`breadcrumbs__steps`,step].join(' ')}>
                        <li></li>
                        <li><span>{t('niki.b2b.cartflow.delivery')}</span></li>
                        <li><span>{t('niki.b2b.cartflow.payment')}</span></li>
                    </ol>
                </div>
                <div className="content">
                    <div className="delivery_content">
                        <h1>{t('niki.b2b.cart.shoppingcart')} {cartIDReal} {cartName}</h1>    
                        <div className="delivery_accordion active" ref={addressesEl}>
                            <div className="delivery_accordion__title" onClick={ExplandCollapseAdresses}>{t('niki.b2b.cartflow.addresses')}</div>
                            <div className="delivery_accordion__content">
                                <DeliveryAddress />
                            </div>
                        </div>                    
                        <div className="delivery_accordion active" ref={deleveryMethodEl}>
                            <div className="delivery_accordion__title" onClick={ExplandCollapseDelivery}>{t('niki.b2b.cartflow.deliverymethod')}</div>
                            <div className="delivery_accordion__content">
                                <DeliveryMethod suppliersData={suppliersData} deliveriesData={deliveriesData} step={step} />
                            </div>
                        </div>
                        {step === 'payment' &&
                            <>
                                <div className="delivery_accordion active" ref={paymentMethodEl}>
                                    <div className="delivery_accordion__title" onClick={ExplandCollapsePayment}>{t('niki.b2b.cartflow.paymentmethod')}</div>
                                    <div className="delivery_accordion__content">
                                        <PaymentMethod />
                                    </div>
                                </div>
                                <div className="delivery_accordion active" ref={additionalNotesEl}>
                                    <div className="delivery_accordion__title" onClick={ExplandCollapseAdditionalNotes}>{t('niki.b2b.cartflow.addnotes')}</div>
                                    <div className="delivery_accordion__content">
                                        <AdditionalNotesContent />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    {suppliersData.length > 0 && 
                        <div className={[`delivery_bottom_fixed_delivery`,`delivery_count_products_fixed`,step].join(' ')}>
                            <div>
                                <p>{t('niki.b2b.cartflow.totalskus')}</p>
                                <p>{t('niki.b2b.feedback.supplier')}</p>
                                <p>{t('niki.b2b.process.deliverytotal')}</p>
                                <p>{t('niki.b2b.orders.totalnet')}</p>
                                <p>{t('niki.b2b.orders.totalvat')}</p>
                            </div>
                            <div>
                                <p>{suppliersData.reduce((acc, item) => acc + parseFloat(item.qty), 0)}</p>
                                <p>{suppliersData.length}</p>
                                <p>{qtyDelivery}</p>
                                <p>{PriceFormat(suppliersData.reduce((acc, item) => acc + parseFloat(item.sum_net), 0))}</p>
                                <p>{PriceFormat(suppliersData.reduce((acc, item) => acc + parseFloat(item.vat), 0))}</p>
                            </div>
                            <div>
                                <p>{t('niki.b2b.cart.carttotal')}</p>
                                <p>{PriceFormat(suppliersData.reduce((acc, item) => acc + parseFloat(item.sum), 0))}</p>
                            </div>
                        </div>
                    }
                    <div className={[`delivery_bottom_fixed`,`delivery_bottom_fixed_delivery`,step].join(' ')}>
                        <NavLink to="/myshoppingcart/" className="btn btn-outline btn-svg-left"><ArrowLeft />{t('niki.b2b.cartflow.modifycart')}</NavLink>
                        <button onClick={GoToPayment} className={`btn ${isAccountBlocked ? '' : 'btn-green'}`} disabled={isAccountBlocked}>{t('niki.b2b.cartflow.payment')}<ArrowRight /></button>
                    </div>
                    <div className={[`delivery_bottom_fixed`, `delivery_bottom_fixed_payment`, 'button_finalize', step].join(' ')}>
                        <button onClick={ModifyDelivery} className="btn btn-outline btn-svg-left"><ArrowLeft />{t('niki.b2b.cartflow.moddelivery')}</button>
                        <button onClick={ShowPopup} className={`btn ${isAccountBlocked ? '' : 'btn-green'}`} disabled={isAccountBlocked}>{t('niki.b2b.cartflow.finalize')}<ArrowRight /></button>
                    </div>
                </div>
                <div className="delivery_popup__wrapper" ref={deliveryPopupEl}>
                    <div className="delivery_popup">
                        <div className="delivery_popup__text">
                            {t('niki.b2b.cartflow.youmustaccept')}
                            <div onClick={() => setIsShowTermsData(true)}>
                                {t('niki.b2b.cartflow.termsofsale')}
                            </div>
                        </div>
                        <div className="delivery_popup__buttons">
                            <button className="btn btn-info" onClick={HidePopup}>
                                {t('niki.b2b.cartflow.donotaccept')}
                            </button>
                            <button
                                className={[`btn btn-green submit_loader`, loadingStatus].join(' ')}
                                onClick={AcceptTerms}
                                disabled={disabled}
                            >
                                <span className="waiting">{t('niki.b2b.cartflow.iaccept')}</span>
                                <span className="loading round_spinner"></span>
                            </button>
                        </div>
                    </div>
                    <div className="delivery_popup__overlay" onClick={HidePopup}></div>
                </div>
            </div>
            <div className={["content", !isShowTermsData && "hidden"].join(' ')}>
                <div className={["static_page_content", "relative"].join(' ')}>
                    <button className="btn btn-primary terms_and_conditions_button_back" type="button" onClick={() => setIsShowTermsData(false)}>{t('niki.b2b.productpage.back')}</button>
                    <h1>{t('niki.b2b.cartflow.termsofsale')}</h1>
                    <div className="dangerouslyInnerHTM__container_text" dangerouslySetInnerHTML={{ __html: termsData }} />
                </div>
            </div>

            {isErrorOrderCreation && <AlertPopup text={t('niki.b2b.order_creation_error')} closeAlert={() => setIsErrorOrderCreation(false)} />}
            {isAccountBlockedModal && <AlertPopup text={t('niki.b2b.entity_account_blocked')} closeAlert={() => setIsAccountBlockedModal(false)}
                towButtons={true} titleFirstButton={'niki.b2b.menu.contact'} onFirstButtonClick={() => navigate('/contact/')}
            />}
        </>
    )
}