import React, { useEffect, useRef, useState } from "react"
import { useSearchParams, NavLink } from "react-router-dom"
import { t } from "i18next"
import { companyName } from '../config.js'
import { getBannerDataLogin, ShowLoader, HideLoader } from "../functions.js"
import { applyAccount, maskEmail, maskPhone } from './loginFunctions'
import { LoginHeader } from './LoginHeader' 

import { useReadOTP } from "react-read-otp"
import { AlertPopup } from '../components/AlertPopup'
import { getRequestOptions, getRequestOptionsNoAuth, fetchDataPhoneOrEmail } from '../helpers/apiHelper'
import { LangSelector } from "./LangSelector"

import "../assets/css/login.css"

const getEntityData = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/user/entity-accounts?include=company,user&realKey=true&type_id=8&itemSegmentations=true`,getRequestOptions())
    const json = await response.json()
    return json
}

const fetchClientInfo = async () => {
    const response = await fetch(`https://geolocation-db.com/json/`)
    const json = await response.json()
    return json
}

if (localStorage.getItem('user.id')) localStorage.setItem('user.id.old',localStorage.getItem('user.id'))

export const EnterCode = () => {
    const [inputCode, setInputCode] = useState('')
    const [wrongCode, setWrongCode] = useState(false)
    const [noEntityData, setNoEntityData] = useState(false)
    const [code, setCode] = useState(['', '', '', '', '', ''])
    const [bannerTwo, setBannerTwo] = useState({})
    const [isOpenOtpEmptyAlert, setIsOpenOtpEmptyAlert] = useState(false)
    const [isCheckCode, setIsCheckCode] = useState(false)

    const inputRefs = useRef([])

    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    useReadOTP(setInputCode)
    const fromURL = `${searchParams.get("from")}`
    const placeholder = `OTP from ${fromURL}`
    // const CodeValid = () => parseInt(inputCode) > 99999 && parseInt(inputCode) < 1000000 ? true : false

    const SubmitCode = (workingCode) => {
        setIsCheckCode(true)
        parseInt(workingCode) === 0 && setIsOpenOtpEmptyAlert(true)
        if (parseInt(workingCode) > 99999 && parseInt(workingCode) < 1000000) {
            const requestOptions = getRequestOptionsNoAuth('POST', null, 'application/json')
            requestOptions.body = JSON.stringify({
                code: workingCode,
                client_info: localStorage.getItem('client.info')
            })

            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/login/verify-code`,requestOptions)
            .then(response => {
                !response.ok && setWrongCode(true)
                return response.json()
            })
            .then(json => {
                if (json.access_token) {
                    localStorage.setItem('user_name',`${json.user.Name}`)
                    localStorage.setItem('access.token',`${json.access_token.trim()}`)
                    localStorage.setItem('refresh.token',`${json.refresh_token.trim()}`)
                    localStorage.setItem('access.token.lifetime',parseInt(Date.now())+parseInt(json.expires_in)*1000)
                    getEntityData().then(json => { 
                        if (json.data) {
                            if (json.data.length === 0) {
                                setNoEntityData(true)
                            } else if (json.data.length === 1) {
                                applyAccount(json.data[0].id)
                            } else {
                                localStorage.setItem('entity_accounts', JSON.stringify(json.data))
                                window.location.href = '/select-account/'
                            }
                        }
                    })
                }
            })
            .catch(error => console.error(error))
            .finally(() => {
                setIsCheckCode(false)
                HideLoader()
            })
        }
    }

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
            e.content_subtype_id === 'J8RvMwowm7k2pNOr' && e.contentType?.data?.real_id === 1 && setBannerTwo(getBannerDataLogin(e))
        })

        try {
            // fetchClientInfo().then(json => setClientInfo(JSON.stringify(json)))
            fetchClientInfo().then(json => localStorage.setItem('client.info',JSON.stringify(json)))
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }

        document.addEventListener('paste', OtpCtrlV)
        return (() => document.removeEventListener('paste', OtpCtrlV))
    // eslint-disable-next-line
    }, [])

    const OtpChangeInput = (event, index) => {
        const value = event.target.value
        setCode((prevCode) => {
            const newCode = [...prevCode]
            newCode[index] = value
            newCode.join('').length === 6 && SubmitCode(newCode.join(''))
            return newCode
        })
        if (value.length > 0 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus()
        }
    }
    
    const OtpKeyDown = (event, index) => {
        if (event.key === 'Backspace' && code[index] === '' && index > 0) {
            const newCode = [...code]
            newCode[index - 1] = ''
            setCode(newCode)
            inputRefs.current[index - 1].focus()
        }
    }
  
    const OtpCtrlV = (event) => {
        event.preventDefault()
        const clipboardData = event.clipboardData || window.clipboardData
        const pastedData = clipboardData.getData('Text')
        const newCode = pastedData
            .slice(0, 6)
            .split('')
            .map((char) => (isNaN(char) ? '' : char))
            newCode.join('').length === 6 && SubmitCode(newCode.join(''))
        setCode(newCode.concat(Array(6 - newCode.length).fill('')))
        inputRefs.current[5].focus()
    }

    const ChangeOTP = e => {
        const ThisValue = parseInt(e.target.value.trim())
        setInputCode(ThisValue)
        parseInt(ThisValue) > 99999 && parseInt(ThisValue) < 1000000 && SubmitCode(ThisValue)
    }

    const [seconds, setSeconds] = useState(299)
    
    useEffect(()=>{
        if (!wrongCode) {
            const interval = setInterval(() => {
                setSeconds(seconds => seconds - 1)
            }, 1000)
            return () => clearInterval(interval)
        }
    },[wrongCode,seconds])

    const ResendOTPSMS = () => {
        const requestOptions = getRequestOptionsNoAuth('POST', null, 'application/json')
        requestOptions.body = JSON.stringify({phone: localStorage.getItem('account.user.phone')})

        ShowLoader()
        fetchDataPhoneOrEmail(`${process.env.REACT_APP_API_DOMAIN}/clients/web/phone`,requestOptions)
        .then(response => {
            if (response) {
                setSeconds(299)
                setWrongCode(false)
                document.querySelector(`.six_digits__single:first-child`).focus()
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    const ResendOTPEmail = () => {
        const requestOptions = getRequestOptionsNoAuth('POST', null, 'application/json')
        requestOptions.body = JSON.stringify({ email: localStorage.getItem('account.user.email') })

        ShowLoader()
        fetchDataPhoneOrEmail(`${process.env.REACT_APP_API_DOMAIN}/login/email-api`,requestOptions)
        .then(response => {
            if (response) {
                setSeconds(60)
                setWrongCode(false)
                document.querySelector(`.six_digits__single:first-child`).focus()
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    const CodeLifeTimer = () => {
        return (!wrongCode && seconds > 0
            ? <div className="alert alert-success">
                {t('niki.b2b.login.wesentcode')} {fromURL === 'Email' ? maskEmail(localStorage.getItem('account.user.email')) : maskPhone(localStorage.getItem('account.user.phone'))}. {t('niki.b2b.login.pleaseenter')} {seconds / 60 > 1 ? Math.floor(seconds / 60) + ':' : ''}
                    {seconds > 60
                        ? (seconds - Math.floor(seconds / 60) * 60) > 9
                            ? seconds - Math.floor(seconds / 60) * 60
                            : `0${seconds - Math.floor(seconds / 60) * 60}`
                        : seconds === 60 ? '1:00' : seconds
                } {t('niki.b2b.login.seconds')}...
            </div>
            : <div className="marginbottom20"><button type="button" className="btn btn-primary" onClick={fromURL === 'Email' ? ResendOTPEmail : ResendOTPSMS}>{t('niki.b2b.login.resendotp')}</button></div>
        )
    }

    return (
        <>
            <div className="login__wrapper">
                <LoginHeader />
                <div className="login__content">
                    <div className="login__header__title">{t('niki.b2b.login.into')}<br />{companyName}</div>

                    {fromURL === 'Email' && <h2>{t('niki.b2b.login.checkemail')}</h2>}
                    {fromURL === 'SMS' && <h2>{t('niki.b2b.login.checkphone')}</h2>}

                    {fromURL === 'Email' && wrongCode && <div className="alert alert-danger">{t('niki.b2b.login.passwordnotcorrect')}</div>}
                    {fromURL === 'SMS' && wrongCode && <div className="alert alert-danger">{t('niki.b2b.login.passwordnotcorrect')}</div>}

                    {noEntityData && <div className="alert alert-danger">{t('niki.b2b.login.noentityaccount')}</div>}

                    <div className="six_digits">
                        {code.map((value, index) => (
                            <input
                                key={index}
                                type="text"
                                value={value}
                                className="six_digits__single"
                                onChange={(event) => OtpChangeInput(event, index)}
                                onKeyDown={(event) => OtpKeyDown(event, index)}
                                ref={(el) => (inputRefs.current[index] = el)}
                                autoFocus={index === 0 ? true : false}
                                pattern="[0-9]*"
                                inputMode="numeric"
                            />
                        ))}
                    </div>

                    {fromURL === 'Email' && <CodeLifeTimer/>}
                    {fromURL === 'SMS' && <CodeLifeTimer/>}

                    <div className="code_input">
                        <input
                            /*
                            type="number"
                            step="1"
                            min="99999"
                            max="1000000"
                            pattern="[0-9]*"
                            */
                            type="text"
                            inputMode="numeric"
                            className="form-control"
                            placeholder={placeholder}
                            onChange={ChangeOTP}
                            value={inputCode}
                            autoComplete="one-time-code"
                            required
                            autoFocus
                        />
                    </div>
                    {fromURL === 'Email' &&
                        (isCheckCode
                            ? <>
                                <div className="forgot_your_email">{t('niki.b2b.login.cannotreceiveemailq')} <span style={{ color: '#888' }}>{t('niki.b2b.login.recover')}</span></div>
                                <div className="prefer_login_by_sms">{t('niki.b2b.login.prefersms')} <span style={{ color: '#888' }}>{t('niki.b2b.login.now')}</span></div>
                            </>
                            : <>
                                <div className="forgot_your_email">{t('niki.b2b.login.cannotreceiveemailq')} <NavLink to="/recover-email/">{t('niki.b2b.login.recover')}</NavLink></div>
                                <div className="prefer_login_by_sms">{t('niki.b2b.login.prefersms')} <NavLink to="/">{t('niki.b2b.login.now')}</NavLink></div>
                            </>
                        )
                    }
                    {fromURL === 'SMS' &&
                        (isCheckCode
                            ? <>
                                <div className="forgot_your_email">{t('niki.b2b.login.cannotreceiveemailq')} <span style={{ color: '#888' }}>{t('niki.b2b.login.recover')}</span></div>
                                <div className="prefer_login_by_sms">{t('niki.b2b.login.prefersms')} <span style={{ color: '#888' }}>{t('niki.b2b.login.now')}</span></div>
                            </>
                            : <>
                                <div className="forgot_your_email">{t('niki.b2b.login.cannotreceivesms')} <NavLink to="/recover-sms/">{t('niki.b2b.login.recover')}</NavLink></div>
                                <div className="prefer_login_by_sms">{t('niki.b2b.login.preferloginemail')} <NavLink to="/login-email/">{t('niki.b2b.login.now')}</NavLink></div>
                            </>
                        )
                    }
                    <LangSelector />
                </div>
                <a href={bannerTwo.url} target={bannerTwo.target} rel="noopener noreferrer" className="login__banner"><img src={bannerTwo.image} alt={bannerTwo.title} /></a>
            </div>
            <div className="loader"></div>
            {isOpenOtpEmptyAlert && <AlertPopup text={t('niki.b2b.login.niki.b2b.login.otpempty')} closeAlert={() => setIsOpenOtpEmptyAlert(false)}/>}
        </>
    )
}