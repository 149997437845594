import React, { useState, useEffect } from "react"
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { t } from "i18next"

import { priceLineID } from '../config.js'
import { PriceFormat, ShowLoader, HideLoader, DataPreloader, setCartIdsToStorage } from "../functions.js"
import { getRequestOptions } from '../helpers/apiHelper'
import { clearCartContent, clearCartIdName, setUuidCart } from '../reduxToolkit/cartSlice'
import { setErrorMessage } from '../reduxToolkit/allproduct'

import { BreadCrumbs } from "../components/BreadCrumbs"

import './../assets/css/orders.css'

import { ReactComponent as OrderIcon } from './../assets/svg/ordersorder.svg'
import { ReactComponent as Invoice } from './../assets/svg/ordersinvoice.svg'
import { ReactComponent as Duplicate } from './../assets/svg/ordersduplicate.svg'

const DateFormat = localDate => localDate && localDate.length > 0 && localDate.split(' ')[0].replaceAll('/','.')
const GetOrderSumm = data => {
    let summ = 0
    // in test json in data goes unit_price = null so we use «|| 1»
    data.forEach(e => summ += parseFloat(e.quantity)*parseFloat(e.unit_price || 1))
    return summ
}
const OpenPDF = e => {
    const uuid = e.target.dataset.uuid
    ShowLoader()
    fetch(`${process.env.REACT_APP_API_DOMAIN}/documents/${uuid}/print?include=details`,getRequestOptions('GET','text/plain'))
    .then(response => response.text())
    .then(url => window.open(`http://${url}`,'_blank','noreferrer'))
    .catch(error => console.error(error))
    .finally(() => HideLoader())
}

export const Orders = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [data, setData] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)

    const OrderExplandCollapse = e => {
        const WorkingClass = `.order.id${e.target.dataset.id}`
        document.querySelector(WorkingClass).classList.toggle('active')
    }

    useEffect(()=>{
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/documents-invoices?document_type=1&include=details,status&searchFields=account_id%3A%3D%3Bkey%3A%3D&realKey=true&search=documentType.id%3A2%3Baccount_id%3A${localStorage.getItem('account.entity.id.real')}%3Bkey%3ANE&limit=1000&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            setData([])
            setDataLoaded(true)
            json.data && json.data.forEach(e => {
                const tempObj = {
                    id: e.id,
                    idReaL: e.id_real, 
                    nuber: e.number,
                    date: DateFormat(e.created_at),
                    status: e.status.data.matchcode,
                    summ: GetOrderSumm(e.details.data),
                    processID: e.process_id,
                    processIDReal: e.process_id_real,
                    SKUs: e.details.data.length,
                    invoice: `${e.key} ${e.serial} - ${String(e.number).padStart(6, '0')}`,
                    source: '-',
                    VAT: e.total_tax_amount,
                    NET: e.total_net_amount,
                    uuid: e.uuid,
                    key: `${e.key} ${e.serial} - `,
                }
                setData(prevState => ([...prevState, tempObj]))
            })
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
        // setData(FakeData)
    }, [])

    const duplicateClick = (e) => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/document-to-cart/${e.target.dataset.uuid}?realKey=true`,getRequestOptions('POST'))
            .then(response => response.json())
            .then(json => {
                if (json.message && json.errors) {
                    dispatch(setErrorMessage(json?.message))
                } else if (json.uuid) {
                    dispatch(clearCartContent())
                    dispatch(clearCartIdName())
                    dispatch(setUuidCart(json.uuid))
                    setCartIdsToStorage(json.uuid, json.real_id, json.id)
                    navigate('/myshoppingcart/')
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }

    return (
        <div className="content">
            <BreadCrumbs itemlist={[{'url':'/account/','title':t('niki.b2b.menu.myaccount')},{'url':'/orders/','title':t('niki.b2b.account.myorders')}]} />
            <div className="orders_content">
                <h1>{t('niki.b2b.account.orders')}</h1>
                <div className="orders">
                    {!dataLoaded && <DataPreloader />}
                    {dataLoaded && data.length > 0 && data.map((e,i)=>
                        <div className={[`order`,`id${e.id.toLowerCase().replace(' ','_')}`].join(' ')} key={`order${i}`}>
                            <div className="order__first">
                                <strong>{t('niki.b2b.feedback.orderid')}</strong>
                                <strong>{t('niki.b2b.debts.orderdate')}</strong>
                                <strong className="right">{PriceFormat(e.summ)}</strong>
                                <span>{e.key}{e.nuber}</span>
                                <span>{e.date}</span>
                                <span className="right"><span className={[`order__first__status`,e.status.toLowerCase()].join(' ')}>{e.status}</span></span>
                                <button
                                    className="order__first__button"
                                    data-id={e.id.toLowerCase().replace(' ','_')}
                                    onClick={OrderExplandCollapse}
                                ></button>
                            </div>
                            <div className="order__second">
                                <div className="order__second__info">
                                    <div className="marginbottom10">{t('niki.b2b.debts.processid')}: {e.processIDReal}</div>
                                    <div className="marginbottom10">
                                        <span className="order__second__two_values">{t('niki.b2b.debts.skus')}: {e.SKUs}</span>
                                        {t('niki.b2b.document')}: {e.invoice}
                                    </div>
                                    <div className="order__second__three_columns">
                                        <span>{t('niki.b2b.orders.source')}: {e.source}</span>
                                        <span>{t('niki.b2b.product.vat')}: {PriceFormat(e.VAT)}</span>
                                        <span>{t('niki.b2b.product.net')}: {PriceFormat(e.NET)}</span>
                                    </div>
                                </div>
                                <div className="order__second__buttons">
                                    <NavLink to={`/order/${e.uuid}/`} className="order__second__button"><OrderIcon />{t('niki.b2b.invoices.vieworder')}</NavLink>
                                    <button data-uuid={e.uuid} onClick={OpenPDF} className="order__second__button"><Invoice />{t('niki.b2b.process.download')}</button>
                                    <button data-uuid={e.uuid} className="order__second__button" onClick={duplicateClick}><Duplicate />{t('niki.b2b.orders.duplicate')}</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}